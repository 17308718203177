import { Header, HeaderSmall, HeaderSmallBox, HeaderTiny, Indent } from "../components/text_display/main_content_text_styling";
import { ErrorMessage } from "../components/themes and styles/error";
import { Bold } from "../components/themes and styles/text_styles";
import { useAuth } from "./auth_provider";
import styled from "styled-components";

const BaseStyling = styled.div`
  position: relative;
  margin: 1rem;

  & > div {
    background-color: #fff;
    //border-style: solid;
    border-width: 1px;
    margin-top: 8px;
    margin-left: 6px;
    margin-right: 6px;
    padding: 8px;
    padding-top: 8px;
    border-radius: 3px;
    border-color: #1c79c1;
  }
`

function AuthFail({mail}){
  return (
    <div>
      <Bold>Authentication failed</Bold>
      <Indent>
        <ErrorMessage>
          The provided mail and/or password are incorrect.
        </ErrorMessage>
      </Indent>
    </div>
  )
}

function AuthError(props){
  return (
    <div>
      <Bold>Error</Bold>
      <Indent>
        <ErrorMessage>
        There was some error while trying to authenticate credentials.
        Please try again later.
        </ErrorMessage>
      </Indent>
    </div>
    )
}

function AuthSuccess(props){
  return <></>
}

function AuthMissing(props){
  return (
    <div>
      <Bold>Unauthenticated</Bold>
      <Indent>
        <ErrorMessage>
          You are not logged in. Please do so to use the website fully.
        </ErrorMessage>
      </Indent>
    </div>
    )
}

export function LoginPage(props){

  const {authStatus} = useAuth();

  return (
    <>
      <Header text={"Login"}/>
      <BaseStyling>
      {
        (authStatus === 'login_error' && <AuthError/>) ||
        (authStatus === 'login_fail' && <AuthFail/>) ||
        (authStatus === 'login_success' && <AuthSuccess/>) ||
        <AuthMissing/>
      }
      </BaseStyling>
    </>
  )
}