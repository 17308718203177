import styled from "styled-components";
export const MainContent = styled.div `
    background-color: rgba(255, 255, 255, 1);
    overflow: auto;
    border-radius: 3px;
    flex-grow: 2;
    position: relative;
    height: calc(100% - 4rem);
    margin: 1%;
`;
