import styled from "styled-components"
import { ThemeColor } from "../themes and styles/colors"
import { BorderSolidLightBlue, BorderSolidSlimBlack } from "../themes and styles/borders"

//tode remove here as is duplicated in variablemanual.js

const VMLOuter = styled.div`
  display: grid;
  grid-template-columns: ${props => props.cols || "auto 1fr"};
  align-items: center;
  overflow: hidden;
  gap: 0.8em;
  padding-top: 0.3rem;
`
const VMLRight = styled.div`
  position: relative;
  padding: 0.5rem;
  word-break: break-word;
  ${BorderSolidSlimBlack}

  &:hover {
    background: ${ThemeColor.grey.hover};
    cursor: pointer;
  }
`

export function VariableLinkStyling({ is_selected, displayName, handleCheckboxClick, handleTextClick }) {
  return (
    <VMLOuter>
      <input type={"checkbox"} onChange={() => handleCheckboxClick()} checked={is_selected} />
      <VMLRight onClick={() => handleTextClick()}>
        {displayName}
      </VMLRight>
    </VMLOuter>
  )
}

export function ContextLinkStyling({ displayName, handleTextClick }) {
  return (
    <VMLOuter cols={"1fr"}>
      <VMLRight onClick={() => handleTextClick()}>
        {displayName}
      </VMLRight>
    </VMLOuter>
  )
}