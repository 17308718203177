import { Collection, Subcollection, Text } from "./structuring_elements";


const subcollection_definition_of_cohort = new Subcollection(
  'Definition Of Cohort',
  [
    new Text('Exclusion', 'analysis_exclusion'),
    new Text('Inclusion', 'analysis_inclusion'),
    new Text('Define Subgroups (if applicable)', 'analysis_subgroups')
  ]
)

const subcollection_data_needed = new Subcollection('Data Needed (Variables)',
  [
    new Text(
      'Description Of Outcome / Dependent Variable(s)',
      'methods_outcome'
    ),
    new Text(
      'Description Of Exposure / Independent Variable(s)',
      'methods_description'
    ),
    new Text(
      'Description Of Confounder / Covariates',
      'methods_confounder'
    )
  ]
)

const subcollection_kind_of_analysis = new Subcollection('What Kind Of Analysis Is Planed?', 
  [
    new Text(
      'Describe All Statistical Methods, Including Those Used To Control For Confounding',
      'analysis_statistical_methods'
    ),
    new Text(
      'Describe Any Methods Used To Examine Subgroups And Interactions',
      'analysis_exam_methods'
    ),
    new Text(
      'Explain How Missing Data Were Addressed',
      'analysis_missing_data'
    ),
    new Text(
      'If Applicable, Explain How Loss To Follow-Up Was Addressed',
      'analysis_loss_fu'
    ),
    new Text(
      'Describe Any Sensitivity Analyses',
      'analysis_sensitivity_analyses'
    )
  ]
)

export const collection_plan_of_analysis = new Collection(
  'Plan of Analysis',
  [
    subcollection_definition_of_cohort,
    subcollection_data_needed,
    new Text('Handling Of Missing Variables', 'analysis_missing_variables'),
    subcollection_kind_of_analysis,
    new Text('Planned Type Of Publication', 'analysis_presentation_type')
  ]
)
