import { fetch_json_endpoint, request_success } from "../RequestHelpers";
import { stringify } from "../helper_functions";
export class VariableSelectionManager {
    selections;
    constructor() {
        this.selections = {};
    }
    containsProposal(proposal_id) {
        return this.selections.hasOwnProperty(proposal_id);
    }
    containsVariableForProposal(proposal_id, variable_id) {
        return (this.containsProposal(proposal_id) &&
            this.selections[proposal_id].hasOwnProperty(variable_id));
    }
    variableHasStatusForKindInProposal(proposal_id, variable_id, kind) {
        return (this.containsVariableForProposal(proposal_id, variable_id) &&
            this.selections[proposal_id][variable_id].hasOwnProperty(kind));
    }
    variableSelectedAsKindForProposal(variable_id, kind, proposal_id) {
        return (this.variableHasStatusForKindInProposal(proposal_id, variable_id, kind) &&
            this.selections[proposal_id][variable_id][kind]);
    }
    setSelection(proposal_id, variable_id, kind, selection_status) {
        if (!this.containsProposal(proposal_id)) {
            this.selections[proposal_id] = {};
        }
        if (!this.containsVariableForProposal(proposal_id, variable_id)) {
            this.selections[proposal_id][variable_id] = {};
        }
        this.selections[proposal_id][variable_id][kind] = selection_status;
        return this;
    }
    setSelections(selections) {
        this.selections = selections;
    }
    getSelectionsForProposalAndKind(proposal_id, kind) {
        if (!this.containsProposal(proposal_id))
            return [];
        let result = [];
        for (const variable_id of Object.keys(this.selections[proposal_id])) {
            if (this.variableSelectedAsKindForProposal(variable_id, kind, proposal_id)) {
                result.push(variable_id);
            }
        }
        return result;
    }
    getSelections() {
        return this.selections;
    }
    async db_set_variables_for_proposal(requestor_mail, auth_token, target_proposal_id, internal_proposal_id) {
        const body = {
            mail: requestor_mail,
            jwt: auth_token,
            proposal_id: target_proposal_id,
            variables: {
                exposure: this.getSelectionsForProposalAndKind(internal_proposal_id, "exposure"),
                confounder: this.getSelectionsForProposalAndKind(internal_proposal_id, "confounder"),
                outcome: this.getSelectionsForProposalAndKind(internal_proposal_id, "outcome")
            }
        };
        const response = await fetch_json_endpoint("https://api.hchs.hamburg/api_endpoints/proposals/set_proposal_variables.php", body);
        if (request_success(response)) {
            return true;
        }
        throw new Error(`some error occured while processing request. Response is ${stringify(response)}`);
    }
}
