
function PagingButtons({ prevPage, nextPage, currentPage, totalElements, elementsPerPage }) {
  return (
    <div>
      <button onClick={prevPage} disabled={currentPage === 1}>Prev</button>
      <button onClick={nextPage} disabled={totalElements <= currentPage * elementsPerPage}>Next</button>
    </div>
  );
}

export function Pagination({currentPage, setCurrentPage, totalElements, elementsPerPage}) {
  // Function to handle next page click
  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  }

  // Function to handle previous page click
  const prevPage = () => {
    if (currentPage > 1) {
    setCurrentPage(currentPage - 1);

    }
  }

  return (
    <>
      <PagingButtons
        prevPage={prevPage}
        nextPage={nextPage}
        currentPage={currentPage}
        totalElements={totalElements}
        elementsPerPage={elementsPerPage}
      />
    </>
  )
}
