import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const HELPER_form_style = styled.form `
    padding: 30px;
    padding-top: 83px;
    position: relative;
    z-index: 1;
    height: 100%;
`;
export function Positioning_nav({ children }) {
    return (_jsx(HELPER_form_style, { children: children }));
}
