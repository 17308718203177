import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { BigButton_base } from "./BigButton_base";
const HELPER_adjustRight = styled.span `
    position: relative;
    font-size: 24px;
    margin-left: auto;
    color: #7875b5;
`;
export function BigButton_IconRight({ icon, button_text, callback }) {
    return (_jsxs(BigButton_base, { onClick: callback, type: "button", children: [_jsx("span", { children: button_text }), _jsx(HELPER_adjustRight, { children: icon })] }));
}
