import styled from "styled-components";
import { css } from "styled-components";
import { BorderSolidLightBlue } from "../themes and styles/borders";

export const BlueFrameBox = styled.div`
    margin: 15px;
    ${BorderSolidLightBlue};
    padding: 0 10px;
    overflow: hidden;
`;

export const BlueFrameBoxTiny = css`
  ${BorderSolidLightBlue};
  border-radius: 1em;
`
