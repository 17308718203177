import styled from "styled-components";
import { hasNumberOfUnsetArguments, isFunction, isString } from 'typescript_helpers';

export const ModalShadow = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2; 
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
`

const ModalContent = styled.div`
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  height: ${props => props.height || 'auto'};
`

const ModalClose = styled.span`
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;

  &:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`

export function Modal({closeModalCallback, children, height}){
  
  return (
    <ModalShadow>
      <ModalContent height={height}>
        <ModalClose onClick={closeModalCallback}>&times;</ModalClose>
          {children}
      </ModalContent>
    </ModalShadow>
  )
}


export class ModalInput{
  constructor( 
    closeModalCallback, 
    processModalCallback, 
  ){
    this.closeModalCallback = this.assignCloseModalCallback(closeModalCallback);
    this.processModalCallback = this.assignProcessModalCallback(processModalCallback);
  }

  ArgError(method, arg_name, arg_expectation, arg_actual){
    return new Error(
      `For Object input of class ModalInput:: input.${method}(${arg_name}):: expect ${arg_name} ${arg_expectation} - ${arg_name} is ${JSON.stringify(arg_actual)}`
    )
  }

  assignText(method, text){
    if(!isString){
      return new Error(method, "text", "to be String", text)
    }
    return text;
  }

  assignLoadingText(text){
    return this.assignText("assignLoadingText", text);
  }

  assignSuccessText(text){
    return this.assignText("assignSuccessText", text);
  }

  assignErrorText(text){
    return this.assignText("assignErrorText", text);
  }

  assignCloseModalCallback(callback){
    if (!isFunction(callback) || !hasNumberOfUnsetArguments(0, callback)){
      throw this.ArgError("assignCloseModalCallback", "callback", "to be function taking 0 arguments", callback);
    }
    return callback;
  }

  assignProcessModalCallback(callback){
    if(!isFunction(callback) || !hasNumberOfUnsetArguments(3, callback)){
      throw this.ArgError("assignProcessModalCallback", "callback", "to be function taking 1 argument. Namely a callback that is called with one of the status values ['error', 'success'] indicating the result of the function call", callback);
    }
    return callback;
  }
}
