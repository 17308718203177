import styled from "styled-components";
import { RowGrid } from "../Grids/RowGrid";
import { PanelBorderStyling } from "../Borders/PanelBorderStyling";
import { OverflowContainerVertical } from "../OverflowContainers/OverflowContainerVertical";
export const ListPanel = styled(RowGrid) `
  margin-left: 15px;
  margin-right: 15px;
  padding: 1rem;
  ${PanelBorderStyling}
  ${OverflowContainerVertical}
`;
