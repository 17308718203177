import { useState, createContext } from "react";
import { structuredClone } from "typescript_helpers";
import { useContext } from "react";

const SelectionContext = createContext();

export const SelectionProvider = ({children}) => {

  const [selectedVariables, updateSelectedVariables] = useState({});

  const getSelectedVariables = () => {
    return structuredClone(selectedVariables);
  }

  const isSelected = variable_id => {
    if (selectedVariables[variable_id] === undefined) return false;
    return selectedVariables[variable_id].is_selected;
  }

  const setSelectedVariables = selected_variables => {
    updateSelectedVariables(selected_variables);
  }

  const contextValues = {
    getSelectedVariables: getSelectedVariables,
    isSelected: isSelected,
    setSelectedVariables: setSelectedVariables
  }


  return (
    <SelectionContext.Provider value={contextValues}>
      {children}
    </SelectionContext.Provider>
  );
}

export function useSelectedVariables(){
  return useContext(SelectionContext);
}
