import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { BlueFrameBox } from "../../Borders/BlueFrameBoxes/BlueFrameBox";
export const Header = styled.h3 `
  word-wrap: break-word;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;
export function HeaderSmall({ text }) {
    return (_jsx(BlueFrameBox, { children: _jsx(Header, { children: text }) }));
}
