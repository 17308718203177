import styled, { css } from "styled-components";
import { ScrollingContentPanel } from "../../../Styling/PackageExports";
export const VariableManualPanelStyling = css `
  border-color: white;
  border-radius: 23px;
  background: white;
`;
export const ScrollingContentPanel_override = styled(ScrollingContentPanel) `
  ${VariableManualPanelStyling};
`;
