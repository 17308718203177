import { isDetail, isDetails, isVisibility } from "./custom_types";
import { deepEqual, isNonNullObject, isOneOf, isString, objectHasKeys, objectKeysValid, structuredClone } from "./helper_functions";
import { HelperNodeSorter } from "./helper_NodeSorter";
export function isNodeLike(maybeNode) {
    return (objectHasKeys(maybeNode, ["id", "type", "visibility", "display_name", "details"]) &&
        isString(maybeNode.id) &&
        isString(maybeNode.type) &&
        isVisibility(maybeNode.visibility) &&
        isString(maybeNode.display_name) &&
        isDetails(maybeNode.details));
}
export class Node {
    id;
    type;
    visibility;
    display_name;
    details;
    initial_details;
    constructor(id, details, type, visibility, display_name) {
        this.id = id;
        this.type = type;
        this.visibility = visibility;
        this.display_name = display_name;
        this.initial_details = this.assignInitialDetails(details);
        this.details = structuredClone(this.initial_details);
    }
    assignInitialDetails(details) {
        let details_copy = [];
        for (const detail of details) {
            details_copy.push(detail);
        }
        return details_copy;
    }
    getNodeCopy() {
        return new Node(this.id, structuredClone(this.details), this.type, this.visibility, this.display_name);
    }
    static isVMContext(node) {
        return (node.type === "context" ||
            node.id.startsWith("Var-Manual-Kontext_") ||
            isOneOf(node.id, [
                "VM_FB_VM_FB",
                "VM_Fragebogen_Benjamin",
                "VM_FB_DIFE_Inworks_VM",
                "VM_HCHS_Soarian_ALL_RS_@bearbeitet",
                "VM_Gerätedaten ALL",
                "Start / Overview",
                "Root",
                "Gerätedaten",
                "Fragebögen",
                "Untersuchungsergebnisse",
                "Sekundärvariablen"
            ]));
    }
    static isVariable(node, excludedVariableNames = []) {
        const id = node.id;
        if (excludedVariableNames.includes(id))
            return false;
        return id !== "" && !Node.isVMContext(node);
    }
    static nodesEqual(n1, n2) {
        if (isNodeLike(n1) || isNodeLike(n2))
            return false;
        const data1 = Node.getNodeData(n1);
        const data2 = Node.getNodeData(n2);
        return (data1.id === data2.id &&
            data1.type === data2.type &&
            data1.visibility === data2.visibility &&
            data1.display_name === data2.display_name &&
            deepEqual(data1.details, data2.details));
    }
    static getNodeData(n) {
        if (n instanceof Node) {
            return n.getNodeCopy();
        }
        return n;
    }
    static getAttributesByName(n) {
        const details = this.getNodeData(n).details;
        let result = {};
        for (const detail of details) {
            result[detail.attributeName] = detail;
        }
        return result;
    }
    static appendDetailContents(c1, c2) {
        return `${c1}\n\n${c2}`;
    }
    static isEmptyDetail(detail) {
        const pattern = /\S/;
        return !pattern.test(detail.attributeContent);
    }
    static filterNodesForPred(nodes, predicate) {
        let result = {};
        for (const node of nodes) {
            if (predicate(node)) {
                result[node.id] = node;
            }
        }
        return Object.values(result);
    }
    static filterForContexts(nodes) {
        return Node.filterNodesForPred(nodes, Node.isVMContext);
    }
    static filterForVariables(nodes) {
        return Node.filterNodesForPred(nodes, Node.isVariable);
    }
    static filterEmptyDetails(details) {
        let filtered = [];
        for (const detail of details) {
            if (!Node.isEmptyDetail(detail)) {
                filtered.push(detail);
            }
        }
        return filtered;
    }
    static detailContainsSearchString(detail, search) {
        search = search.toLowerCase();
        if (detail.attributeName.toLowerCase().includes(search))
            return true;
        if (detail.attributeContent.toLowerCase().includes(search))
            return true;
        return false;
    }
    static searchStringMatchInDetails(search, details) {
        let match = false;
        while (!match && details.length > 0) {
            const detail = details.pop();
            match = this.detailContainsSearchString(detail, search);
        }
        return match;
    }
    static nodeMatchesSearch(node, searchList) {
        const details = Node.getNodeData(node).details;
        //only check further for all search terms that are not already found in display name or id
        let reducedList = searchList.filter((elem) => {
            elem = elem.toLowerCase();
            return !(node.id.toLowerCase().includes(elem) || node.display_name.toLowerCase().includes(elem));
        });
        let hasMissmatch = false;
        while (reducedList.length > 0 && !hasMissmatch) {
            const search = reducedList.pop();
            hasMissmatch = !this.searchStringMatchInDetails(search, structuredClone(details));
        }
        return !hasMissmatch;
    }
    static getDisplayName(n) {
        return n.display_name;
    }
    static compareDisplayName(n1, n2) {
        return n1.display_name.localeCompare(n2.display_name);
    }
    static isNodeDetail(det) {
        //is seems to have some bug, since testing the function returns true for possible detail {attributeContent: "content", attributeName: "name", other: "visible"}
        return (isNonNullObject(det) &&
            objectKeysValid(det, ["attributeName", "attributeContent", "visibility"]) &&
            isDetail(det));
    }
    getDetail(attributeName) {
        for (const detail of this.details) {
            if (detail.attributeName === attributeName)
                return detail;
        }
        return false;
    }
    static mergeDetails(details1, details2) {
        let details_by_name = details1.reduce((akk, detail) => ({ ...akk, [detail.attributeName]: detail }), {});
        for (const detail2 of details2) {
            const name = detail2.attributeName;
            if (!details_by_name.hasOwnProperty(name)) {
                details_by_name[name] = detail2;
            }
            else {
                details_by_name[name] = {
                    attributeName: name,
                    attributeContent: Node.appendDetailContents(details_by_name[name].attributeContent, detail2.attributeContent),
                    visibility: detail2.visibility
                };
            }
        }
        return Object.values(details_by_name);
    }
    static sortNodes(nodes) {
        let actual_nodes = nodes.map((node) => {
            if (Node.isNode(node))
                return node;
            let nodelike = node;
            return new Node(nodelike.id, nodelike.details, nodelike.type, nodelike.visibility, nodelike.display_name);
        });
        return HelperNodeSorter.sortNodes(actual_nodes);
    }
    static isNode(n) {
        return (n instanceof Node);
    }
    static isNodeLike(n) {
        return isNodeLike(n);
    }
    static getNodesById(nodes) {
        return Object.fromEntries(nodes.map((node) => {
            let nCopy = node.getNodeCopy();
            return [nCopy.id, nCopy];
        }));
    }
}
