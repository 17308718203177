import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PaddedBorderSlimBlack } from "../../Styling/Borders/PaddedBorderSlimBlack";
import { ColumnGrid } from "../../Styling/Grids/ColumnGrid";
import { LineHalf } from "../../Styling/Grids/GridLines/LineHalf";
import { LineItem } from "../../Styling/Grids/GridLines/LineItem";
import styled from "styled-components";
const ProposalExpander = styled.div `
  &:hover {
    cursor: pointer;
    background-color: rgba(12,12,12,0.2);
    border-radius: 1rem;
  }
`;
export function ProposalEntryHeader({ proposalExpandCallback, proposal_base }) {
    return (_jsx(_Fragment, { children: _jsx(PaddedBorderSlimBlack, { children: _jsxs(ColumnGrid, { "$template_columns": "repeat(auto-fit, minmax(100, 1fr))", "$row_gap": "1rem", "$column_gap": "2rem", children: [_jsx(LineHalf, { children: _jsx(ProposalExpander, { onClick: proposalExpandCallback, children: _jsx(LineItem, { align: { textAlign: "left" }, title: "Title", text: proposal_base.getProposalAttribute('intro_project_title') }) }) }), _jsx(LineHalf, { children: _jsx(LineItem, { title: "Author", text: `${proposal_base.getProposalAttribute('intro_applicant_firstname')} ${proposal_base.getProposalAttribute('intro_applicant_lastname')}` }) })] }) }) }));
}
