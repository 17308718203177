import { createContext, useEffect, useState } from "react";
import { HeaderTiny, Indent, MainContentGridLayout } from "../text_display/main_content_text_styling";
import { LinkPanelStyling, OuterPanel, SelectedVariablesPanel } from "./panel_styling";
import { DetailsPanel } from "./details_panel";
import { ColumnGrid, XYGrid, ScrollingListContainer, BorderGrid } from "../divider and frames/layouts";
import { InfoMenu } from "../widgets/inlo_options_menu";
import { useCurrentVariables, useGraph, useProposalSelectionMapping, useVMHistories } from "./contexts";
import { Node } from "typescript_helpers";
import styled from "styled-components";
import { BorderSolidLightBlue, BorderSolidSlimBlack } from "../themes and styles/borders";
import { ThemeColor } from "../themes and styles/colors";
import { RoundIconWrapper, SearchIcon } from "../icons/font_awesome_icons";
import { Bold } from "../themes and styles/text_styles";
import { RedDividerBar } from "../divider and frames/divider_bars";
import { useAuth } from "../../authentication/auth_provider";
import { isOneOf } from "typescript_helpers";
import { VMSearchBar as Ref_VMSearchBar } from "typescript_helpers";

const VariableAttributeContext = createContext(null);

export const variablemanual_standalone_association_value = {
  proposal_id: "none",
  kind: "variablemanual_standalone_selection"
}

const VMLRight = styled.div`
  position: relative;
  padding: 0.5rem;
  word-break: break-word;
  ${BorderSolidSlimBlack}

  &:hover {
    background: ${ThemeColor.grey.hover};
    cursor: pointer;
  }
`

export function VariableLinkStyling({ is_selected, displayName, handleCheckboxClick, handleTextClick }) {
  return (
    <VMLOuter>
      <input type={"checkbox"} onChange={() => handleCheckboxClick()} checked={is_selected} />
      <VMLRight onClick={() => handleTextClick()}>
        {displayName}
      </VMLRight>
    </VMLOuter>
  )
}

export function ContextLinkStyling({ displayName, handleTextClick, background }) {
  return (
    <VMLOuter cols={"1fr"} background={background}>
      <VMLRight onClick={() => handleTextClick()}>
        {displayName}
      </VMLRight>
    </VMLOuter>
  )
}

const VMLOuter = styled.div`
  display: grid;
  grid-template-columns: ${props => props.cols || "auto 1fr"};
  align-items: center;
  overflow: hidden;
  gap: 0.8em;
  padding-top: 0.3rem;
  background: ${props => props.background || ""};
`

const CurrentNodeStyle = styled.div`
  position: relative;
  padding: 0.5rem;
  word-break: break-word;
  ${BorderSolidLightBlue}

  &:hover {
    background: ${ThemeColor.grey.hover};
    cursor: pointer;
  }
`
/*
  * Der Link muss 
  * 1. den display namen des nodes anzeigen
  * 2. bei click auf den link die aktuellen node details setzen (für das aktuelle proposal und die selection kind)
  * 3. bei click auf die checkbox die variable für das aktuelle proposal als selection kind auswählen
  * 
*/
function VMLink({ is_selected, displayName, id, association, type }) {
  const { getNodeForId } = useGraph();
  const { variablesManager, setVariablesManager } = useCurrentVariables();
  const [selected, setSelected] = useState(is_selected);
  const { selectionManager, setSelectionManager, forceUpdateClock } = useProposalSelectionMapping();

  let backgroundColor = null;
  switch (type) {
    case "topic":
      backgroundColor = "green";
      break;
    case "source":
      backgroundColor = "yellow";
      break;
    case "area":
      backgroundColor = "red";
      break;
  }

  const handleTextClick = () => {
    let newManager = variablesManager.setCurrentVariable(association.proposal_id, association.kind, id);
    setVariablesManager(newManager);
  };
  const handleCheckboxClick = () => {
    let newManager = selectionManager.setSelection(association.proposal_id, id, association.kind, !selected);
    setSelectionManager(newManager);
  };

  const onSelectionChange = () => {
    // only update if selection in manager has been changed
    const selectedInManager = selectionManager.variableSelectedAsKindForProposal(id, association.kind, association.proposal_id);
    if (!selected && selectedInManager) {
      setSelected(selectedInManager);
      return;
    }
    if (selected && !selectedInManager) {
      setSelected(selectedInManager);
      return;
    }
  };

  useEffect(() => { onSelectionChange(); }, []);
  useEffect(() => { onSelectionChange(); }, [forceUpdateClock]);

  const node = getNodeForId(id);
  if (Node.isVariable(node)) {
    return (
      <VariableLinkStyling
        handleTextClick={() => { handleTextClick() }}
        handleCheckboxClick={() => { handleCheckboxClick() }}
        is_selected={selected}
        displayName={displayName} />
    )
  }
  if (Node.isVMContext(node)) {
    return (
      <ContextLinkStyling
        handleTextClick={() => { handleTextClick() }}
        displayName={displayName}
        background={backgroundColor}
      />
    )
  }
}

function CurrentHistoryEntry({ node }) {

  return (
    <VMLOuter cols={"1fr"}>
      <CurrentNodeStyle>
        {Node.getDisplayName(node)}
      </CurrentNodeStyle>
    </VMLOuter>
  )
}

function NonCurrentHistoryEntry({ association, node }) {
  const { jumpBackInHistory } = useVMHistories();

  const handleTextClick = () => {
    jumpBackInHistory(association.proposal_id, association.kind, node.id);
  }

  return (
    <ContextLinkStyling displayName={Node.getDisplayName(node)} handleTextClick={handleTextClick} />
  )
}


function HistoryEntry({ baseIndentWidth, baseIndentUnit, indentFactor, node_id, is_current_entry, association }) {
  const graphContext = useGraph();

  try {
    const node = graphContext.getNodeForId(node_id);
    return (
      <Indent height={"auto"} margin_left={`${indentFactor * baseIndentWidth}${baseIndentUnit}`}>
        {(is_current_entry &&
          <CurrentHistoryEntry node={node} />) ||
          <NonCurrentHistoryEntry
            node={node}
            association={association} />}
      </Indent>
    )
  }
  catch (err) {
    return <></>
  }
}

function HistoryEntries({ association, history_ids }) {
  const baseIndentWidth = 1;
  const baseIndentUnit = "rem";

  const removeStartAsFirstEntry = (history_ids) => {
    if (history_ids[0] === "Start / Overview") {
      return history_ids.slice(1);
    }
    return history_ids;
  }

  return <>
    {
      removeStartAsFirstEntry(history_ids).map((id, index) => {
        return (
          <HistoryEntry
            key={index}
            baseIndentUnit={baseIndentUnit}
            baseIndentWidth={baseIndentWidth}
            indentFactor={index}
            node_id={id}
            is_current_entry={index === (history_ids.length - 1)}
            association={association}
          />
        )
      })
    }
  </>
}

function VMHistoryPanel({ association }) {

  const [entries, setEntries] = useState(<></>);
  const { forceUpdateClock, getLastHistoryElements } = useVMHistories();

  const updateHistoryEntries = () => {
    setEntries(
      <HistoryEntries
        association={association}
        history_ids={getLastHistoryElements(association.proposal_id, association.kind, 5)}
        current_node_id={""} />
    )
  }

  useEffect(() => { updateHistoryEntries() }, [forceUpdateClock]);

  return (
    <LinkPanelStyling template_rows={"auto auto auto 1fr"}>
      <HeaderTiny text={"History"} />
      <div style={{ margin: "0rem 1rem 1rem 1rem" }}><HistoryEntry node_id={"Start / Overview"} association={association} /></div>
      <RedDividerBar width={"calc(100% - 2rem)"} style={{ marginLeft: "1rem", marginRight: "1rem" }} />
      <ScrollingListContainer>
        {entries}
      </ScrollingListContainer>
    </LinkPanelStyling>
  )
}

function VMLinkPanel({ headerText, vm_nodes, tooltip_text, association }) {

  const { selectionManager } = useProposalSelectionMapping();
  const { isAdmin } = useAuth();
  const renderVMLink = (node, index) => {
    if (!isAdmin && isOneOf(node.visibility, ["internal", "hidden", "intern"])) {
      return <></>;
    }
    return (
      <VMLink
        is_selected={selectionManager.variableSelectedAsKindForProposal(node.id, association.kind, association.proposal_id)}
        displayName={Node.getDisplayName(node)}
        id={node.id}
        key={index}
        association={association}
        type={node.type}
      />
    )
  }

  return (
    <LinkPanelStyling template_rows={"auto 1fr"}>
      <ColumnGrid template_columns={"1fr auto"}>
        <HeaderTiny text={headerText} />
        <InfoMenu tooltip_text={tooltip_text} />
      </ColumnGrid>
      <ScrollingListContainer>
        {vm_nodes.map((node, index) => renderVMLink(node, index))}
      </ScrollingListContainer>
    </LinkPanelStyling>
  )
}

function VariableRelationsListView({ association, currentId }) {
  const graphContext = useGraph();
  const sortedNodesToCurrent =
    Node.sortNodes(
      graphContext.getContextsPointingAtCurrentNode(currentId)
    ).concat(
      Node.sortNodes(
        graphContext.getVariablesPointingAtCurrentNode(currentId)
      )
    );
  const sortedNodesFromCurrent = Node.sortNodes(
    graphContext.getContextsPointedToByCurrentNode(currentId)
  ).concat(
    Node.sortNodes(
      graphContext.getVariablesPointedToByCurrentNode(currentId)
    )
  );
  return (
    <>
      <VMLinkPanel
        headerText={"Topics/Variables reached from the selected variable"}
        vm_nodes={sortedNodesToCurrent}
        tooltip_text={"In this panel you can see a listing of all variables and topics that lead to the selected variable/topic"}
        association={association} />
      <VMLinkPanel
        headerText={"Topics/Variables that lead to the selected variable"}
        vm_nodes={sortedNodesFromCurrent}
        tooltip_text={"In this panel you can see a listing of all variables and topics you can reach form the selected variable/topic. \n Example: Topics are highlighted in gray. Variables can be selected directly in this view."}
        association={association}
      />
      <VMHistoryPanel association={association} />

    </>
  )
}

const VariableManualGridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr 1fr;
  overflow: hidden;
  row-gap: 1rem;
  column-gap: 1rem;
  height: 100%;
`

function SelectedVariablesList({ association }) {
  const { selectionManager, forceUpdateClock } = useProposalSelectionMapping();
  const graphContext = useGraph();
  const [selectedVariables, setSelectedVariables] = useState(null);

  const getDisplayName = (variable_id) => {
    let node = graphContext.getNodeForId(variable_id);
    return Node.getDisplayName(node);
  }

  useEffect(() => { setSelectedVariables(getSelectedVariables()); }, []);
  useEffect(() => { setSelectedVariables(getSelectedVariables()); }, [forceUpdateClock]);

  const getSelectedVariables = () => {
    let selections = selectionManager.getSelectionsForProposalAndKind(association.proposal_id, association.kind);
    return selections.map((variable_id, index) => {
      return (
        <VMLink
          key={index}
          is_selected={true}
          displayName={getDisplayName(variable_id)}
          id={variable_id}
          association={association}
        />
      )
    })
  }

  return (
    <ScrollingListContainer>
      {selectedVariables}
    </ScrollingListContainer>
  )
}

const VMSearchBar = styled.input`
  border: none;
  border-radius: 4px;
  border-bottom: 2px solid #d1d1d4;
  background: none;
  font-weight: 600;
  transition: 0.2s;
  position: relative;
  margin: 0rem;
  margin-left: 0px;

  &:active,
    &:focus,
    &:hover {
        outline: none;
        border-bottom-color: #6a679e;
  }

`

function SearchBar({ association }) {
  const [searchText, setSearchText] = useState("");
  const { executeNodeSearch } = useGraph();
  const { variablesManager, setVariablesManager } = useCurrentVariables();

  const triggerSearch = () => {
    if (!searchText.trim().length) return;
    let id_searchNode = executeNodeSearch(searchText);
    let newManager = variablesManager.setCurrentVariable(
      association.proposal_id,
      association.kind,
      id_searchNode);
    setVariablesManager(newManager);
    setSearchText("");
  }

  const submitSearchEnterCallback = (event) => {
    if (event.key === "Enter") {
      triggerSearch();
    }
  }

  return (
    <OuterPanel
      grid_column="1 / span 3">
      <ColumnGrid template_columns={"auto 1fr"} padding={"0px"} column_gap={"1rem"}>
        <RoundIconWrapper
          padding={"0rem"}
          margin_left={"0.5rem"}
          onClick={() => { triggerSearch() }}
        >
          <SearchIcon />
          <Bold style={{ marginLeft: "0.5rem" }}>
            Search
          </Bold>
        </RoundIconWrapper>
        <VMSearchBar value={searchText}
          onKeyDown={submitSearchEnterCallback}
          onChange={(event) => { setSearchText(event.target.value) }} />
      </ColumnGrid>
    </OuterPanel>
  )
}

export function VariableManual({ association }) {
  const [currentVariableDetails, setCurrentVariableDetails] = useState([]);
  const [currentSelectionId, setCurrentSelectionId] = useState("");

  const { variablesManager, setVariablesManager, forceUpdateClock } = useCurrentVariables();
  const onCurrentVariableChange = () => {
    const currentIdFromManager = variablesManager.getCurrentVariableForProposalAndKind(association.proposal_id, association.kind);
    if (currentSelectionId !== currentIdFromManager) {
      if (currentIdFromManager === "") {
        setCurrentSelectionId("Start / Overview");
      }
      else {
        setCurrentSelectionId(currentIdFromManager);
      }
    }
  };
  useEffect(() => { onCurrentVariableChange(); }, [forceUpdateClock]);
  useEffect(() => {
    if (!variablesManager.currentVariableSetForProposalAndKind(association.proposal_id, association.kind)) {
      let newManager = variablesManager.setCurrentVariable(association.proposal_id, association.kind, "Start / Overview");
      setVariablesManager(newManager);
    }
  }, []);
  const ContextValues = {
    variableDetails: currentVariableDetails,
    setVariableDetails: setCurrentVariableDetails,
  }
  return (
    <VariableAttributeContext.Provider value={ContextValues}>
      <BorderGrid background={"antiquewhite"}>
        <VariableManualGridLayout>
          <SearchBar association={association} />
          <DetailsPanel association={association} currentId={currentSelectionId} />
          <SelectedVariablesPanel association={association} selectedVariables={<SelectedVariablesList association={association} />} />
          <VariableRelationsListView association={association} currentId={currentSelectionId} />
        </VariableManualGridLayout>
      </BorderGrid>
    </VariableAttributeContext.Provider>
  )
}
