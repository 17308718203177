import { useState } from "react";
import { BlueFrameBox } from "./divider and frames/frame_boxes";
import { Header, MainContentElementContainer } from "./text_display/main_content_text_styling";
import { InputElementButtonContainer, InputElementCheckbox, InputElementContainerSideBySidePositioning, InputElementContainerTopDownPositioning, InputItem, InputItemMail } from "./text_inputs/main_content_inputs";
import { Submit } from "./buttons/menu_area_buttons";
import { usePageLayout } from "./page_layouts";
import { fetch_json_endpoint, onErrorResponse, request_success } from "../nonComponentFunctions/RequestHelpers";
import { useAuth } from "../authentication/auth_provider";
import { PaddedBorderSlimBlack } from "./themes and styles/borders";
import { ColumnGrid } from "./divider and frames/layouts";
import { LineItem } from "./proposals/issued_proposals";

const URL_SET_USER_PASSWORD = "https://api.hchs.hamburg/api_endpoints/set_user_password.php";
const URL_NEW_ADD_USER = "https://api.hchs.hamburg/api_endpoints/add_user.php";


//migrated
class UserData {
  constructor(){
    this.db_response = null;
    this.error_message = "";

    this.requestMade = false;

    this.user_data = {
      id: { db_column: "id", value: null},
      email: { db_column: "name", value: null},
      password: { db_column: "password", value: null},
      password_repeat: { db_column: null, value: null},
      isActiveFlag: { db_column: "active", value: null},
      firstName: { db_column: "first_name", value: null},
      lastName: { db_column: "last_name", value: null},
      isAdminUser: { db_column: null, value: null}
    }
  }

  onMissingAttribute(attribute_id){
    if(!this.user_data.hasOwnProperty(attribute_id)) throw new Error(`user data has no property ${attribute_id}`);
  }

  getAttribute(attribute_id){
    this.onMissingAttribute(attribute_id);
    return this.user_data[attribute_id].value;
  }

  setAttribute(attribute_id, attribute_content){
    this.onMissingAttribute(attribute_id);
    this.user_data[attribute_id].value = attribute_content;
  }

  validatePasswords(){
    return this.getAttribute('password') === this.getAttribute('password_repeat');
  }

  setUserData(user_data_collection){
    for (const [
      attribute_id,
      attribute_content ] of Object.entries(user_data_collection)){
        this.setAttribute(attribute_id, attribute_content);
      }
  }

  async db_set_user_password(auth_token){
    if(this.requestMade)return;
    this.requestMade = true;
    const request_body = {
      mail: this.getAttribute('email'),
      jwt: auth_token,
      password: this.getAttribute('password'),
      password_repeat: this.getAttribute('password_repeat')
    };
    const response = await fetch_json_endpoint(URL_SET_USER_PASSWORD, request_body);
    this.db_response = response;
    try{
      onErrorResponse(response);
    } catch (error){
      throw error;
    }
    return this;
  }

  async db_add_new_user(auth_token){
    if(this.requestMade)return;
    this.requestMade = true;
    const request_body = {
      mail: this.getAttribute('email'),
      jwt: auth_token,
      password: this.getAttribute('password'),
      password_repeat: this.getAttribute('password_repeat'),
      is_admin: this.getAttribute('isAdminUser'),
      first_name: this.getAttribute('firstName'),
      last_name: this.getAttribute('lastName'),
      is_active: this.getAttribute('isActiveFlag')
    };
    const response = await fetch_json_endpoint(URL_NEW_ADD_USER, request_body);
    this.db_response = response;
    
    try{
      onErrorResponse(response);
    } catch (error){
      throw error;
    }
    return this;
  }
}

//migrated
export function AccountManagement(props){
  const {mail, role} = useAuth();
    return (
        <>
            <Header text={"Account"}/>
            <BlueFrameBox>
              <PaddedBorderSlimBlack
                style={{margin: "1rem 0px"}}>
          <ColumnGrid
            template_columns={"repeat(auto-fit, minmax(250px, 1fr))"}
            row_gap={"1rem"}
            column_gap={"2rem"}
            >
              <LineItem title={"User Mail"} text={mail} />
              <LineItem title={"User Role"} text={role} />
          </ColumnGrid>
        </PaddedBorderSlimBlack>
            </BlueFrameBox>
        </>
    )
}

//migrated
const account_management_modal_close_callback = ()=>{};
const password_change_modal_input_func = (token, user_data) => {
  return async (modal_status_func, modal_status_text_func, modal_message_func) => {
    modal_status_func('loading');
    modal_status_text_func('Changing Password');

    if(!user_data.validatePasswords()){
      modal_status_func('error');
      modal_status_text_func('Error');
      modal_message_func('Password and retyped password do not match!');
      return;
    }
    try{
      const user_data_response = await user_data.db_set_user_password(token);
      const response = user_data_response.db_response;
      if(request_success(response)){
        modal_status_func('success');
        modal_status_text_func('Success');
        modal_message_func(`Password for user ${user_data_response.getAttribute('email')} has been changed`);
      }
      onErrorResponse(response);
    }
    catch (error){
      modal_status_func('error');
      modal_status_text_func('Error');
      modal_message_func(error.message);
    }
  }
}

//migrated
const add_user_modal_input_func = (token, user_data) => {
  return async (modal_status_func, modal_status_text_func, modal_message_func) => {
    modal_status_func('loading');
    modal_status_text_func('Adding new user');

    if(!user_data.validatePasswords()){
      modal_status_func('error');
      modal_status_text_func('Error');
      modal_message_func('Password and retyped password do not match!');
      return;
    }

    try{
      const user_data_response = await user_data.db_add_new_user(token);
      const response = user_data_response.db_response;
      if(request_success(response)){
        modal_status_func('success');
        modal_status_text_func('Success');
        modal_message_func(`User with mail ${user_data.getAttribute('email')} has been created.`);
      }
      onErrorResponse(response);
    }
    catch (error){
      modal_status_func('error');
      modal_status_text_func('Error');
      modal_message_func(error.message);
    }
  }
}

//migrated
export function ChangePassword(props){

  const [password, setPassword] = useState("");
  const [password_repeat, setRepeat] = useState("");

  const {token, mail} = useAuth();
  const {activateModal} = usePageLayout();

  const submit_callback = ()=>{
    const user_data = new UserData();
    user_data.setAttribute('email', mail);
    user_data.setAttribute('password', password);
    user_data.setAttribute('password_repeat', password_repeat);

    activateModal(account_management_modal_close_callback, password_change_modal_input_func(token, user_data));
  }

  return (
    <>
      <Header text={"Change Password"}/>
      <BlueFrameBox>
        <MainContentElementContainer >
          {PasswordFields(setPassword, setRepeat, "")}
          <InputElementButtonContainer>
            <Submit button_text={"Submit"} callback={submit_callback}/>
          </InputElementButtonContainer>
        </MainContentElementContainer>
      </BlueFrameBox>
    </>
  )
}

//migrated
function PasswordFields(setPassword, setRepeat, label) {
  return <InputElementContainerTopDownPositioning label={label}>
    <InputItem>
      <input
        onChange={(event) => setPassword(event.target.value)}
        type="password"
        placeholder="Password" />
    </InputItem>
    <InputItem>
      <input
        onChange={(event) => setRepeat(event.target.value)}
        type="password"
        placeholder="Retype Password" />
    </InputItem>
  </InputElementContainerTopDownPositioning>;
}

//migrated
export function AdminSetNewUser(){
  const [userMail, setUserMail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [password, setPassword] = useState("");
  const [password_repeat, setRepeat] = useState("");
  const [submit, setSubmit] = useState(false);

  const {token} = useAuth();
  const {activateModal} = usePageLayout();

  const submit_callback = ()=>{
    const user_data = new UserData();
    user_data.setAttribute('email', userMail);
    user_data.setAttribute('password', password);
    user_data.setAttribute('password_repeat', password_repeat);
    user_data.setAttribute('firstName', firstName);
    user_data.setAttribute('lastName', lastName);
    user_data.setAttribute('isActiveFlag', true);
    user_data.setAttribute('isAdminUser', isAdminUser);
    
    !submit && 
    activateModal(
      account_management_modal_close_callback, 
      add_user_modal_input_func(token, user_data)) &&
    setSubmit(true);
  }

  return (
    <>
      <Header text={"Add new user"}/>
      <BlueFrameBox>
        <MainContentElementContainer>
          <InputElementContainerSideBySidePositioning label={"Name"}>
            <InputItem>
              <input 
                onChange={(event)=> setFirstName(event.target.value)}
                type="text"
                placeholder="First name"/>
            </InputItem>
            <InputItem>
              <input 
                onChange={(event)=> setLastName(event.target.value)}
                type="text"
                placeholder="Last name"/>
            </InputItem>
          </InputElementContainerSideBySidePositioning>
          <InputElementContainerTopDownPositioning label={"Credentials"}>
            <InputItemMail mailCallback={setUserMail}/>
            <InputItem>
              <input 
                onChange={(event)=> setUserMail(event.target.value)}
                type="text"
                placeholder="Email"/>
            </InputItem>
            <InputItem>
              <InputElementCheckbox 
                label={"Admin User?"}
                checked={isAdminUser}
                type={"checkbox"}
                onChange={(event)=>{
                  setIsAdminUser(!isAdminUser);
                }}
                />
            </InputItem>
          </InputElementContainerTopDownPositioning>
          {PasswordFields(setPassword, setRepeat, "Initial Password")}
          <InputElementButtonContainer>
            <Submit button_text={"Submit"} callback={submit_callback}/>
          </InputElementButtonContainer>
        </MainContentElementContainer>
      </BlueFrameBox>
    </>
  )
}

//migrated
export function AdminSetPassword(){
  const [userMail, setUserMail] = useState("");
  const [password, setPassword] = useState("");
  const [password_repeat, setRepeat] = useState("");

  const {token} = useAuth();
  const {activateModal} = usePageLayout();

  const submit_callback = ()=>{
    const user_data = new UserData();
    user_data.setAttribute('email', userMail);
    user_data.setAttribute('password', password);
    user_data.setAttribute('password_repeat', password_repeat);
    
    activateModal(account_management_modal_close_callback, password_change_modal_input_func(token, user_data));
  }

  return (
    <>
      <Header text={"Set User Password"}/>
      <BlueFrameBox>
        <MainContentElementContainer >
          <InputElementContainerTopDownPositioning label={"User Data"}>
            <InputItem>
              <input 
                onChange={(event)=> setUserMail(event.target.value)}
                type="text"
                placeholder="User Mail"/>
            </InputItem>
          </InputElementContainerTopDownPositioning>
                {PasswordFields(setPassword, setRepeat, "Password")}
          <InputElementButtonContainer>
            <Submit button_text={"Submit"} callback={submit_callback}/>
          </InputElementButtonContainer>
        </MainContentElementContainer>
      </BlueFrameBox>
    </>
  )
}
