import { Node } from "./Node";
export class HelperNodeSorter {
    static order_num_comperator(n1, n2) {
        const order1 = n1.getDetail("order-num");
        const order2 = n2.getDetail("order-num");
        if (!order1 || !order2)
            return 0;
        const n1_num = parseInt(order1.attributeContent, 10);
        const n2_num = parseInt(order2.attributeContent, 10);
        return n1_num - n2_num;
    }
    static insertIntoSource(source, node) {
        const node_copy = node.getNodeCopy();
        let order_num = node.getDetail("order-num");
        if (order_num === false) {
            source.not_numbered.push(node_copy);
        }
        else {
            source.numbered.push(node_copy);
        }
    }
    static sortSources(sources) {
        let keys = Object.keys(sources);
        keys.sort((a, b) => a.localeCompare(b));
        return keys.map((key) => sources[key]);
    }
    static sortCollector(collector) {
        let flattened = [];
        //sort context nodes
        collector.contexts.sort(Node.compareDisplayName);
        flattened = flattened.concat(collector.contexts);
        //order sources for further processing
        let sorted_sources = this.sortSources(collector.variableSubsetSourceTable);
        //for each source in alphabetical order append sorted node groupe
        for (const source of sorted_sources) {
            //append explicitely numbered nodes after sorting
            if (source.hasOwnProperty('numbered')) {
                source.numbered.sort(this.order_num_comperator);
                flattened = flattened.concat(source.numbered);
            }
            //append remaining nodes sorted alphabetically according to their display name
            if (source.hasOwnProperty('not_numbered')) {
                source.not_numbered.sort((a, b) => { return a.display_name.localeCompare(b.display_name); });
                flattened = flattened.concat(source.not_numbered);
            }
        }
        return flattened;
    }
    /*
    * the nodes are sorted as follows
    * 1. All nodes that represent some context are sorted alphabetically and are first in list
    * 2. The following nodes are grouped by their source and the order of the groups is
    *     according to the alphabetically order of the source name
    * 3. In each source group the nodes are further divided into nodes that have a order number assigned
    *     and those that have no such number assigned.
    *     The nodes having an order number are sorted according to this number and preceede the nodes without explicit ordering
    *     The nodes without an order number are alphabetically sorted according to their display name
    */
    static sortNodes(nodes) {
        let collector = {
            contexts: [],
            variableSubsetSourceTable: {
                "unknownSource": {
                    not_numbered: [],
                    numbered: []
                }
            }
        };
        for (const node of nodes) {
            if (Node.isVMContext(node)) {
                collector.contexts.push(node);
            }
            else {
                let table_detail = node.getDetail("source-table-name");
                if (table_detail === false) {
                    //the name of the source table is unknown thus the current node can't be grouped according by the table it originates from
                    this.insertIntoSource(collector.variableSubsetSourceTable.unknownSource, node);
                }
                else {
                    let table_name = table_detail.attributeContent;
                    if (!collector.variableSubsetSourceTable.hasOwnProperty(table_name)) {
                        //the collector doesn't have the entry for the source represented by the table name yet
                        //add the data structure to collect nodes belonging to that source
                        collector.variableSubsetSourceTable[table_name] = {
                            not_numbered: [],
                            numbered: []
                        };
                    }
                    this.insertIntoSource(collector.variableSubsetSourceTable[table_name], node);
                }
            }
        }
        return this.sortCollector(collector);
    }
}
