import { isNonNullObject, isString } from "../helper_functions";
function isActualCurrentVariableEntry(elem) {
    return (isCurrentVariableEntry(elem) &&
        isString(elem.current));
}
function isCurrentVariableEntry(elem) {
    return (isNonNullObject(elem) &&
        elem.hasOwnProperty('current') &&
        (isString(elem.current) || elem.current === null));
}
export class CurrentVariableManager {
    currentVariables;
    lastTouchedElements;
    constructor() {
        this.currentVariables = {};
        this.lastTouchedElements = {
            proposal_id: "",
            kind: "",
            variable_id: ""
        };
    }
    containsProposal(proposal_id) {
        return this.currentVariables.hasOwnProperty(proposal_id);
    }
    containsKindForProposal(proposal_id, kind) {
        return (this.containsProposal(proposal_id) &&
            this.currentVariables[proposal_id].hasOwnProperty(kind));
    }
    currentVariableSetForProposalAndKind(proposal_id, kind) {
        return (this.containsKindForProposal(proposal_id, kind) &&
            isCurrentVariableEntry(this.currentVariables[proposal_id][kind]));
    }
    getCurrentVariableForProposalAndKind(proposal_id, kind) {
        return (this.currentVariableSetForProposalAndKind(proposal_id, kind) &&
            isActualCurrentVariableEntry(this.currentVariables[proposal_id][kind]) &&
            this.currentVariables[proposal_id][kind].current);
    }
    getLastTouchedElements() {
        return this.lastTouchedElements;
    }
    setCurrentVariable(proposal_id, kind, variable_id) {
        if (!this.containsProposal(proposal_id)) {
            this.currentVariables[proposal_id] = {};
        }
        this.currentVariables[proposal_id][kind] = { 'current': variable_id };
        this.lastTouchedElements = { proposal_id: proposal_id, kind: kind, variable_id: variable_id };
        return this;
    }
}
