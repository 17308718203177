//migrated
export const ThemeColor = {
  lightblue : "#1c79c1",
  red: "rgb(234, 0, 0)",
  lightred: "rgba(234, 0, 0, 0.26)",
  linkpurple: "rgb(76, 72, 157)",
  checkboxyellow: {
    normal: "#f9dd94",
    hover: "#f7d06e"
  },
  grey: {
    normal: "#D3D4DA",
    hover: "#DCDDE1"
  }
}
