import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BigButton_base } from "./BigButton_base";
import styled from "styled-components";
const AdjustLeft = styled.span `
    position: relative;
    font-size: 24px;
    color: #7875b5;
    padding-right: 10px;
`;
export function BigButton_IconLeft({ callback, button_text, icon }) {
    return (_jsxs(BigButton_base, { onClick: callback, type: "button", children: [_jsx(AdjustLeft, { children: icon }), _jsx("span", { children: button_text })] }));
}
