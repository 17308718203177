import { jsx as _jsx } from "react/jsx-runtime";
import { useGraph } from "../Contexts/GraphContext";
import { useCurrentVariables } from "../Contexts/CurrentVariablesContext";
import { SearchBar } from "../Styling/Components/Search/SearchBar";
import styled from "styled-components";
import { VariableManualPanelStyling } from "./Layouts/Panels/PanelStylings";
const SearchBar_override = styled(SearchBar) `
  ${VariableManualPanelStyling};
  color: "crimson";
`;
export function VMSearchBar({ association }) {
    const { executeNodeSearch } = useGraph();
    const { variablesManager, setVariablesManager } = useCurrentVariables();
    const triggerSearch = (searchText) => {
        if (!searchText.trim().length)
            return;
        let id_searchNode = executeNodeSearch(searchText);
        let newManager = variablesManager.setCurrentVariable(association.proposal_id, association.kind, id_searchNode);
        setVariablesManager(newManager);
    };
    const onEnterTriggerSearch = (key, searchText) => {
        if (key === "Enter")
            triggerSearch(searchText);
    };
    return (_jsx(SearchBar, { callbackTriggerSearch: triggerSearch, callbackInputKeyDown: onEnterTriggerSearch }));
}
