import { PageLayoutNavigationAreaRight } from "./page_layouts";
import { ContentRoutes, MenuRoutes } from "./routes";


function LandingPage(props){
    return (
        <PageLayoutNavigationAreaRight 
            content={<ContentRoutes/>}
            menu={<MenuRoutes/>}
        />
    )
}

export default LandingPage;