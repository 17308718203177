import { jsx as _jsx } from "react/jsx-runtime";
import { useState, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
const LOGIN_VALIDATION_URL = "https://api.hchs.hamburg/api_endpoints/get_validate_login.php";
//Erzeugt Context für Funktionen, die mit Authentifizierung zu tun haben
const AuthContext = createContext('initializing');
//Wrapper Funktion, um den Authentication Context zu laden
export const useAuth = () => {
    return useContext(AuthContext);
};
export const AuthProvider = ({ children }) => {
    console.log("render AuthProvider()");
    const [token, setToken] = useState(null);
    const [requestFeedbackMessage, setMessage] = useState("");
    const [authStatus, setAuthStatus] = useState("");
    const [userRole, setUserRole] = useState("");
    const [user_mail, setUserMail] = useState("");
    const [logged_in, set_logged_in] = useState(false);
    console.log("AuthProvider pre useNavigate");
    const navigate = useNavigate();
    console.log("AuthProvider post useNavigate");
    const onLoginFail = () => {
        setMessage("Login Failed. Username or password are incorrect.");
        setAuthStatus('login_fail');
        setToken(null);
        navigate("/login");
    };
    const onLoginError = () => {
        setMessage("Login Failed due to some error. Please wait and try again.");
        setAuthStatus('login_error');
        setToken(null);
        navigate("/login");
    };
    const onLoginSuccess = (response) => {
        setMessage('Login success');
        setToken(response.token);
        setUserRole(response.role);
        setUserMail(response.mail);
        setAuthStatus('login_success');
        set_logged_in(true);
        navigate("/start");
    };
    //    const mockLogin = (userDataJSON) => {
    //        onLoginSuccess({token: "mock login token"});
    //    }
    function handleLoginValidationResponse(resp) {
        switch (resp.status) {
            case 'login_fail':
                onLoginFail();
                break;
            case 'server_error':
                onLoginError();
                break;
            case 'login_success':
                onLoginSuccess(resp);
                break;
        }
    }
    async function get_login_validation_from_server(userData, callback) {
        const request = {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: `mail=${userData.mail}&password=${userData.password}`
        };
        fetch(LOGIN_VALIDATION_URL, request)
            .then((response) => { return response.json(); })
            .then((result) => { callback(result); })
            .catch((error) => {
            const result = {
                'status': 'server_error',
                'message': error
            };
            callback(result);
        });
    }
    const onLogin = (userData) => {
        setUserMail(userData.mail);
        get_login_validation_from_server(userData, (response) => { handleLoginValidationResponse(response); });
    };
    const value = {
        token: token,
        mail: user_mail,
        role: userRole,
        logged_in: logged_in,
        onLogin: onLogin,
        onLogout: null,
        onRequest: null,
        isAdmin: (userRole === 'admin'),
        authStatus: authStatus
    };
    return (_jsx(AuthContext.Provider, { value: value, children: children }));
};
