import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from "styled-components";
import { RedDividerBar } from "../../../../Styling/Borders/Dividers/RedDivider/RedDividerBar";
import { Indent } from "../../../../Styling/Structuring/Indent";
import { HeaderSmall } from "../../../../Styling/TextMarkup/Headers/HeaderSmall";
import { Label } from "../../../../Styling/TextMarkup/Labels/Label";
import { DetailsRenderer } from "./DetailsRenderer";
import { SelectVariableCheckbox } from "../../../SelectVariableCheckbox";
const CheckboxLine = styled.span `
  float: right;
  margin-right: 30px;
  position: relative;
`;
function VariableDetailAndCheckboxLine({ association, variable_id }) {
    return (_jsxs(Indent, { "$margin_left": "24px", children: [_jsx(Label, { children: "Details" }), _jsxs(CheckboxLine, { children: ["Select Variable", _jsx(SelectVariableCheckbox, { association: association, variable_id: variable_id, scale: "2" })] })] }));
}
export function VariableRenderer({ node, association }) {
    return _jsxs(_Fragment, { children: [_jsx(HeaderSmall, { text: node.display_name }), _jsx(VariableDetailAndCheckboxLine, { association: association, variable_id: node.id }), _jsx(RedDividerBar, { width: "95%" }), _jsx(DetailsRenderer, { details: node.details, variable_id: node.id })] });
}
