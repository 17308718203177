import { Modal } from "./modal";


export function OverlayContentModal({content, closeModalCallback}){
  
  return (
    <Modal 
      closeModalCallback={closeModalCallback}
      height={'80%'}>
        {content}
    </Modal>
  )
}