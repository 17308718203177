import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
import { CurrentVariableManager } from "../Managers/CurrentVariableManager";
const CurrentVariablesContext = createContext('initializing');
export const CurrentVariablesProvider = ({ children }) => {
    const [variablesManager, setVariablesManager] = useState(new CurrentVariableManager());
    const [forceUpdateClock, setForceUpdateClock] = useState(true);
    const expose_setManager = (newManager) => {
        setVariablesManager(newManager);
        setForceUpdateClock(!forceUpdateClock);
    };
    const expose_value = {
        variablesManager: variablesManager,
        setVariablesManager: expose_setManager,
        forceUpdateClock: forceUpdateClock
    };
    return (_jsx(CurrentVariablesContext.Provider, { value: expose_value, children: children }));
};
export const useCurrentVariables = () => {
    return useContext(CurrentVariablesContext);
};
