import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
import { VariableSelectionManager } from "../Managers/VariableSelectionManager";
const ProposalSelectionMappingContext = createContext('initializing');
export const SelectionMappingProvider = ({ children }) => {
    const [selectionManager, setSelectionManager] = useState(new VariableSelectionManager());
    const [forceUpdateClock, setForceUpdateClock] = useState(true);
    const expose_setManager = (newManager) => {
        setSelectionManager(newManager);
        setForceUpdateClock(!forceUpdateClock);
    };
    const expose_value = {
        variablesManager: selectionManager,
        setVariablesManager: expose_setManager,
        forceUpdateClock: forceUpdateClock
    };
    return (_jsx(ProposalSelectionMappingContext.Provider, { value: expose_value, children: children }));
};
export const useProposalSelectionMapping = () => {
    return useContext(ProposalSelectionMappingContext);
};
