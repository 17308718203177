import { collection_methodology } from "./nonComponentFunctions/proposal_structure/collection_methodology"
import { collection_introduction } from "./nonComponentFunctions/proposal_structure/collection_introduction"
import { collection_plan_of_analysis } from "./nonComponentFunctions/proposal_structure/collection_plan_of_analysis"
import { collection_further_information } from "./nonComponentFunctions/proposal_structure/collection_further_information"
import { collection_biomaterials } from "./nonComponentFunctions/proposal_structure/collection_biomaterials"
import { NamelessGrouping } from "./nonComponentFunctions/proposal_structure/structuring_elements"

export const proposal_structure = new NamelessGrouping(
  [
    collection_introduction,
    collection_methodology,
    collection_plan_of_analysis,
    collection_biomaterials,
    collection_further_information
  ]
)
















