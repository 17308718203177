import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { ThemeColor } from "../../ThemeColors";
import { useState } from "react";
import Collapsible from "react-collapsible";
const CollapseContent = styled.div `
  margin-left: 1rem;
  margin-right: 0.5rem;
  padding: 10px 15px 15px;
  border: none;
  font-size: 15px;
  color: #615f5f;
  line-height: 27px;
  background: #f3f3f9;
`;
const CollapseHeader = styled.div `
  display: block;
  padding: 0.3rem 0.3rem;
  background: #fff;
  font-size: 18px;
  font-weight: bold;
  color: ${ThemeColor.linkpurple};
  border: 1px solid #ececec;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  position: relative;
  transition: all 0.1s ease-in 0s;

  &:before {
    content: "+";
    font-family: "Font Awesome 5 Free";
    width: 25px;
    height: 25px;
    line-height: 28px;
    font-size: 15px;
    font-weight: 900;
    color: ${ThemeColor.linkpurple};
    text-align: center;
    position: absolute;
    top: 8px;
    right: 15px;
    transform: rotate(180deg);
    transition: all 0.3s ease-in 0s;
  }
`;
export const StyledCollapsible = ({ headerText, children }) => {
    const [open, setOpen] = useState(true);
    const handleTriggerClick = () => {
        setOpen(!open);
    };
    return (_jsx(Collapsible, { transitionTime: 200, easing: '', trigger: _jsx(CollapseHeader, { children: headerText }), open, handleTriggerClick, children: _jsx(CollapseContent, { children: children }) }));
};
