import { useNavigate } from "react-router";
import { useAuth } from "./auth_provider";
import { useEffect } from "react";
import { TESTMODE } from "../App";


export function RequireAuth({children, redirect_url = false}){
  const {logged_in} = useAuth();
  const navigate = useNavigate();  

  useEffect(()=>{
    if(TESTMODE) return;
    if(!logged_in){
      navigate(redirect_url || "/");
    }
  }, [])
  if(TESTMODE) {
    return <>{children}</>;
  }

  if(logged_in){
    return <>{children}</>;
  }
}

export function HideExceptAdmin({children}){
  const {isAdmin, role} = useAuth();
  if(isAdmin){
    return <>{children}</>
  }
}

export function HideExceptAuth({children}){
  const {logged_in} = useAuth();
  if(logged_in){
    return <>{children}</>
  }
}

export function RequireAdmin({children, redirect_url = false}){
  const {isAdmin, logged_in} = useAuth();
  const navigate = useNavigate();

  useEffect(()=>{
    if(!logged_in || !isAdmin){
      navigate(redirect_url || "/");
    }
  }, [])

  if(logged_in && isAdmin){
    return <>{children}</>
  }
}
