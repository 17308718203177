import { Proposal } from "./Proposal"


const TestProposalData =
{
  "id": "177",
  "USER": "a.scholz-hehn@uke.de",
  "date": "2020-12-08 15:33:46",
  "Variables": "vf_ace_001, vf_ace_002, vf_ace_003, vf_ace_004, vf_ace_005, vf_ace_006, vf_ace_007, vf_ace_008, vf_ace_009, vf_ace_010, vf_degs_i29, vf_eq_5d_001, vf_eq_5d_002, vf_eq_5d_003, vf_eq_5d_004, vf_eq_5d_005, vf_eq_5d_006, klin_krebs_100, klin_krebs_101, klin_krebs_1011, klin_krebs_1012, klin_krebs_102, klin_krebs_1021, klin_krebs_1022, klin_krebs_103, klin_krebs_1031, klin_krebs_1032, klin_krebs_104, klin_krebs_1041, klin_krebs_1042, klin_krebs_105, klin_krebs_1051, klin_krebs_1052, klin_krebs_106, klin_krebs_1061, klin_krebs_1062, klin_krebs_107, klin_krebs_1071, klin_krebs_0021, klin_krebs_1072, klin_krebs_0031, klin_krebs_1073, klin_krebs_0041, klin_krebs_1074, klin_krebs_0051, klin_krebs_1075, klin_krebs_0061, klin_krebs_1076, klin_krebs_0071, klin_krebs_1077, klin_krebs_0081, klin_krebs_1078, klin_krebs_108, klin_krebs_1081, klin_krebs_1082, klin_krebs_109, klin_krebs_1091, klin_krebs_1092, klin_krebs_110, klin_krebs_1101, klin_krebs_1102, klin_krebs_111, klin_krebs_1111, klin_krebs_1112, klin_krebs_112, klin_krebs_1121, klin_krebs_1122, klin_krebs_113, klin_krebs_1131, klin_krebs_1132, klin_krebs_114, klin_krebs_1141, klin_krebs_1142, klin_krebs_115, klin_krebs_1151, klin_krebs_1152, klin_krebs_116, klin_krebs_1161, klin_krebs_1162, klin_krebs_117, klin_krebs_1171, klin_krebs_1172, klin_krebs_118, klin_krebs_1181, klin_krebs_1182, klin_krebs_119, klin_krebs_1191, klin_krebs_1192",
  "intro_applicant_firstname": "Ingo",
  "intro_applicant_lastname": "Schäfer",
  "intro_project_title": "Association of adverse childhood experiences with specific cancer types in adulthood ",
  "intro_background": "Adverse childhood experiences increase the risk of many physical diseases in later adult age and therefore are of great importance for public health (Felitti et al., 1998; Bellis et al., 2014). \r\nContradictory findings exist regarding cancer risk in adulthood and childhood adversity. Previous studies mostly investigated general cancer diagnosis and not specific cancer types (Holman et al., 2017). Furthermore, no data is available regarding health related quality of life in cancer patients with and without adverse childhood experiences. \r\nThe ACE (Adverse Childhood Experiences)-questionnaire in its German Version (Schäfer et al.; 2014) is a well-established instrument for quantifying one's adverse experiences during childhood (so called \"ACE-Score\").",
  "intro_objectives": "We hypothesize that the amount of adverse factors during childhood (ACE-score) positively relates to the occurrence of cancer in later adulthood. We expect a dose-response relationship between the ACE-Score and occurrence of cancer dependent on onset and entity. Moreover, we expect a dose-response relationship between the ACE-Score and health related quality of life. We do not presume the traumatic experiences per se to cause the elevated risk for cancer but rather assume that the respective associations are moderated by risk factors like tobacco consumption, alcohol consumption and chronic inflammatory diseases. Nonetheless, other so far unknown factors might substantially contribute as well. \r\nAs the existing studies (e.g. Holman et al., 2017) did not investigate relationships between ACE and specific types of cancer, we aim to explore relationships between the 10 most prevalent types of cancer and the ACE-score.",
  "intro_overlap": "Not applicable",
  "intro_novelty": "To our knowledge, we are the first to not only investigate association between ACE and cancer in general, but in specific cancer types in a broad sample. Further, the health-realted-quality of life with positive cancer diagnosis has not been analysed before. ",
  "methods_outcome": "Prevalence of all cancer types, age of initial diagnosis, health related quality of life (EQ 5D), global health (DEGS 29)",
  "methods_description": "Adverse Childhood Experiences (ACE)",
  "methods_confounder": "tobacco use, alcohol and drug use, obesity, nutrition, physical activity, family history of cancer, signs of chronic inflammation (e.g. elevated CRP levels, elevated saliva and urine cortisol levels) and chronic inflammatory diseases (e.g. chronic pancreatitis, chronic inflammatory bowel disease, reflux esophagitis).",
  "methods_design": "cross-sectional",
  "methods_design_prosp": "",
  "methods_design_other": "",
  "methods_sample_size": "10000",
  "methods_sample_size_exception": "",
  "methods_power_calc": "In the baseline HCHS data 4348 participants have recorded an ACE score=0 and 4509 participants scored one or more ACEs. Assuming a cancer prevalence of 11%, a binomial response (ACE=0 vs. ACE>=0) and a significance level of 5%, we find that the study can detect an effect of OR = 1.202 with 80% power. The effect can be interpreted as cancer is associated with 20% increased odds in having a higher ACE score. This effect is considered realistic and clinically relevant.  ",
  "methods_setting_baseline": "baseline",
  "methods_setting_yearly": "",
  "methods_setting_yearly_year": "",
  "methods_setting_mri": "",
  "methods_setting_mri_cardio": "",
  "methods_setting_mri_neuro": "",
  "methods_setting_other": "",
  "methods_setting_other_statement": "",
  "methods_timeline": "Project start: August 2020\r\nDuration: 12 months \r\n1.-6. month: analyses of the data\r\n7.-12. month: publication and presentation of the data in peer-reviewed international journals",
  "methods_bias_limitations": "The cross-sectional design will allow to analyse associations between ACE-scores and diagnoses of cancer but will not allow to examine causal relationships. Both, ACE and somatic diagnoses are self-reported. However, these limitations are shared by all currently existing studies in the field.  ",
  "analysis_exclusion": "Incomplete ACE-questionnaires. ",
  "analysis_inclusion": "Completed questionnaires",
  "analysis_subgroups": "ACE=0, ACE=1, ACE=2-3, ACE ≥4; Subgroups depending on the type of cancer.",
  "analysis_missing_variables": "Only completed questionnaires will be included.",
  "analysis_statistical_methods": "Exploratory analyses are planned. Relationships with cancer diagnoses will be examined using logistic regression analyses with the above mentioned confounders as covariates. ",
  "analysis_exam_methods": "On the basis of ACE-scores, four groups will be created (ACE=0, ACE=1, ACE=2-3, ACE≥4). Relationships between ACE-scores and the ten most frequent types of cancer will be examined. ",
  "analysis_missing_data": "Only analyses of completed questionnaires are planned.",
  "analysis_loss_fu": "no follow-up",
  "analysis_sensitivity_analyses": "n.a.",
  "analysis_presentation_type": "Descriptive statistics, presentation of the results of the hypothesis test and the analysis in tables and figures.",
  "need_bio": "yes",
  "biomaterial_which": "some materials",
  "biomaterial_number": "15",
  "biomaterial_volume": "",
  "biomaterial_marker": "",
  "biomaterial_procedure": "",
  "biomaterial_multiple": "",
  "biomaterial_where": "",
  "biomaterial_financial": "",
  "biomaterial_shipment": "",
  "biomaterial_transp_company": "",
  "biomaterial_return": "",
  "biomaterial_leftover": "",
  "other_ip_rights": "none",
  "other_partners": "Department of Psychiatry and Psychotherapy\r\nDepartment of Hematology and Oncology\r\nDepartment of Psychosomatic Medicine\r\n\r\n",
  "other_financial": "Contributions by the departments mentioned above",
  "other_manuscript": "Schäfer, Scholz-Hehn, Block, Lotzin, Löwe, Bokemeyer, Kühn, Gallinat ",
  "final": "1",
  "status": null
}

const test_data2 = { "id": "1640",
  "USER": "b.ostendorf@uke.de",
  "date": null,
  "Variables": null,
  "intro_applicant_firstname": "first_name",
  "intro_applicant_lastname": "last name",
  "intro_project_title": "proposal title",
  "intro_background": "Hintergrund",
  "intro_objectives": "Zielsetzung",
  "intro_overlap": "Overlap to other proposals",
  "intro_novelty": "novelty of proposal",
  "methods_outcome": "outcome",
  "methods_description": "description",
  "methods_confounder": "confounder",
  "methods_design": "proposal design",
  "methods_design_prosp": "prosp methods design",
  "methods_design_other": "design other",
  "methods_sample_size": "17.000",
  "methods_sample_size_exception": "exception to sample size",
  "methods_power_calc": "power calculation",
  "methods_setting_baseline": true,
  "methods_setting_yearly": true,
  "methods_setting_yearly_year": "Year of yearly questionnaire",
  "methods_setting_mri": true,
  "methods_setting_mri_cardio": true,
  "methods_setting_mri_neuro": true,
  "methods_setting_other": true,
  "methods_setting_other_statement": "Other setting statement / description",
  "methods_timeline": "timeline",
  "methods_bias_limitations": "limitations ",
  "analysis_exclusion": "exclusion",
  "analysis_inclusion": "inclusion",
  "analysis_subgroups": "subgroup definition",
  "analysis_missing_variables": "missing variables",
  "analysis_statistical_methods": "statistic methods",
  "analysis_exam_methods": "examination methods",
  "analysis_missing_data": "missing data management",
  "analysis_loss_fu": "something regarding loss",
  "analysis_sensitivity_analyses": "sensitiviy analysis description",
  "analysis_presentation_type": "presentation type",
  "need_bio": true,
  "biomaterial_which": "which biomaterials to use",
  "biomaterial_number": "number of materials to use",
  "biomaterial_volume": "volume",
  "biomaterial_marker": "markers to use",
  "biomaterial_procedure": "bio procedure",
  "biomaterial_multiple": "multiple biomaterials",
  "biomaterial_where": "location of biomaterial exam",
  "biomaterial_financial": "financial of bio",
  "biomaterial_shipment": "shipping of materials",
  "biomaterial_transp_company": "transport company",
  "biomaterial_return": "can bio be returned",
  "biomaterial_leftover": null,
  "other_ip_rights": null,
  "other_partners": "partners",
  "other_financial": "financial related stuff",
  "other_manuscript": "author list",
  "final": null,
  "status": "proposal issued" 
}

export const TestProposal = new Proposal();
TestProposal.setProposalData(TestProposalData);

export const TestProposal2 = new Proposal();
TestProposal2.setProposalData(test_data2);

