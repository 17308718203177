import styled from "styled-components";
export const TextInput_baseStyle = styled.input `
    border: none;
    border-bottom: 2px solid #d1d1d4;
    border-radius: 4px;
    background: none;
    padding: 10px;
    padding-left: 24px;

    font-weight: 700;
    width: 75%;
    transition: 0.2s;
    position: relative;

    &:active,
    &:focus,
    &:hover {
        outline: none;
        border-bottom-color: #6a679e;
    }
`;
