import styled from "styled-components";
import { BaseGrid } from "./BaseGrid";
export const AutoHeightRowGrid = styled(BaseGrid) `
  height: auto;
  width: ${props => props.$width || "100%"};
  place-content: ${props => props.$place_content};
  grid-template-rows: ${props => props.$template_rows};
  grid-template-columns: 1fr;
  grid-auto-rows: ${props => props.$auto_rows || "max-content"};
`;
