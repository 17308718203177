export class VMHistoryManager {
    histories;
    constructor() {
        this.histories = {};
    }
    containsHistoryForProposal(proposal_id) {
        return this.histories.hasOwnProperty(proposal_id);
    }
    containsHistoryForVariableKindForProposal(proposal_id, kind) {
        return (this.containsHistoryForProposal(proposal_id) &&
            this.histories[proposal_id].hasOwnProperty(kind));
    }
    ensureExistingEntry(proposal_id, kind) {
        if (!this.containsHistoryForProposal(proposal_id))
            this.histories[proposal_id] = {};
        if (!this.containsHistoryForVariableKindForProposal(proposal_id, kind))
            this.histories[proposal_id][kind] = [];
    }
    addToHistory(proposal_id, kind, variable_id) {
        this.ensureExistingEntry(proposal_id, kind);
        this.histories[proposal_id][kind].push(variable_id);
        return this;
    }
    getLastHistoryElements(proposal_id, kind, numberOfElements) {
        if (!this.containsHistoryForVariableKindForProposal(proposal_id, kind))
            return [];
        return this.histories[proposal_id][kind].slice(-numberOfElements);
    }
    revertToLastIdInHistory(proposal_id, kind, variable_id) {
        let history_copy = structuredClone(this.histories[proposal_id][kind]);
        let last_element = null;
        while (history_copy.length !== 0 && last_element !== variable_id) {
            last_element = history_copy.pop();
        }
        if (last_element === variable_id) {
            //push previously poped target variable_id back on history
            history_copy.push(last_element);
            this.histories[proposal_id][kind] = history_copy;
            return { revertStatus: true, manager: this };
        }
        return { revertStatus: false, manager: this };
    }
}
