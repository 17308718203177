import styled from "styled-components";
import { ThemeColor } from "../../../ThemeColors";
export const RedBarBase = styled.span `
  border-radius: 10px;
  border-radius: 10px;
  background-color: ${ThemeColor.red};
  border-style: solid;
  border-color: ${ThemeColor.red};
  display: inline-block;
  justify-self: center;

`;
