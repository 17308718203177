import styled from "styled-components";
export const ModalShadow = styled.div `
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2; 
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
`;
