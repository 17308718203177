import styled from "styled-components";
export const MenuAreaCollapsed = styled.div `
    position: absolute;
    top: 20px;
    right: 0px;
    margin-right: 4%;
    height: 30px;
    width: 30px;
    
`;
