import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { TextInput_baseStyle } from "./TextInput_baseStyle";
import { isFunction } from "../../../../helper_functions";
const HELPER_wrapper = styled.div `
    padding: 20px 0px;
    position: relative;
`;
const HELPER_adjustLeft = styled.span `
    position: absolute;
    top: 29px;
    color: #7875b5;
`;
export function TextInputWithIconLeft({ icon, changeCallback, placeholder, isVisible, keyDownCallback: keyDownCallback }) {
    return (_jsxs(HELPER_wrapper, { children: [_jsx(HELPER_adjustLeft, { children: icon }), _jsx(TextInput_baseStyle, { onChange: (event) => {
                    changeCallback(event.target.value);
                }, onKeyDown: (event) => {
                    if (isFunction(keyDownCallback)) {
                        keyDownCallback(event.key);
                    }
                }, type: isVisible ? "text" : "password", placeholder: placeholder })] }));
}
