import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { ColumnGrid } from "../../Grids/ColumnGrid";
import { RoundIconWrapper } from "../../Icons/Wrappers/RoundIconWrapper";
import { SearchIcon } from "../../Icons/SearchIcon";
import { Bold } from "../../TextMarkup/Bold";
import { useState } from "react";
import { ScrollingContentPanel_override } from "../../../Variablemanual/Layouts/Panels/PanelStylings";
const SearchInput = styled.input `
  border: none;
  border-radius: 4px;
  border-bottom: 2px solid #d1d1d4;
  background: none;
  font-weight: 600;
  transition: 0.2s;
  position: relative;
  margin: 0rem;
  margin-left: 0px;

  &:active,
    &:focus,
    &:hover {
        outline: none;
        border-bottom-color: #6a679e;
  }
`;
export function SearchBar({ callbackTriggerSearch, callbackInputKeyDown }) {
    const [searchText, setSearchText] = useState("");
    return (_jsx(ScrollingContentPanel_override, { "$template_rows": "1fr", "$grid_column": "1 / span 3", children: _jsxs(ColumnGrid, { "$template_columns": "auto 1fr", "$column_gap": "1rem", children: [_jsxs(RoundIconWrapper, { "$padding": "0rem", "$margin_left": "0.5rem", onClick: () => { callbackTriggerSearch(searchText); }, children: [_jsx(SearchIcon, {}), _jsx(Bold, { style: { marginLeft: "0.5rem" }, children: "Search" })] }), _jsx(SearchInput, { value: searchText, onKeyDown: (event) => {
                        callbackInputKeyDown(event.key, searchText);
                        setSearchText("");
                    }, onChange: (event) => { setSearchText(event.target.value); } })] }) }));
}
