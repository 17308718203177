import styled from "styled-components";
import { BorderSolidSlimBlack } from "../../Styling/Borders/BorderSolidSlimBlack";
import { ThemeColor } from "../../Styling/ThemeColors";
import { BorderSolidLightBlue } from "../../Styling/Borders/BorderSolidLightBlue";
export const VMLRight = styled.div `
  position: relative;
  padding: 0.5rem;
  word-break: break-word;
  ${BorderSolidSlimBlack}

  &:hover {
    background: ${ThemeColor.grey.hover};
    cursor: pointer;
  }
`;
export const VMLOuter = styled.div `
  display: grid;
  grid-template-columns: ${props => props.$template_columns || "auto 1fr"};
  align-items: center;
  overflow: hidden;
  gap: 0.8em;
  padding-top: 0.3rem;
  background: ${props => props.$background || ""};
`;
export const CurrentNodeStyle = styled.div `
  position: relative;
  padding: 0.5rem;
  word-break: break-word;
  ${BorderSolidLightBlue}

  &:hover {
    background: ${ThemeColor.grey.hover};
    cursor: pointer;
  }
`;
