import styled, { css } from "styled-components";

//migrated
export const FullHeightGrid = styled.div`
  height: 100%;
  display: grid;
`;

//migrated
export const ColumnGrid = styled(FullHeightGrid)`
  grid-template-columns: ${props => props.template_columns};
  padding: ${props => props.padding || "0px"};
  row-gap: ${props => props.row_gap || "0px"};
  column-gap: ${props => props.column_gap || "0px"};
`

//migrated
export const AutoHeightRowGrid = styled.div`
  display: grid;
  height: ${props => props.height || "auto"};
  width: ${props => props.width || "100%"};
  grid-template-columns: ${props => props.column_template || "1fr"};
  grid-template-rows: ${props => props.row_template};
  grid-auto-rows: ${props => props.auto_row_template};
  row-gap: ${props => props.row_gap || "0px"};
  padding: ${props => props.padding || "0px"};
  place-content: ${props => props.place_content};
`;

//migrated
export const RowGrid = styled(FullHeightGrid)`
  grid-template-rows: ${props => props.template_rows};
  grid-template-columns: 1fr;
  column-gap: ${props => props.column_gap || "0px"};
  row-gap: ${props => props.rowGap || "0px"};
  grid-auto-rows: ${props => props.autoRows || "max-content"};
  padding: ${props => props.padding || "0px"};
`

//migrated
export const XYGrid = styled(FullHeightGrid)`
  grid-template-rows: ${props => props.template_rows};
  grid-template-columns: ${props => props.template_columns};
  row-gap: ${props => props.rowGap || "0px"};
  column-gap: ${props => props.columnGap || "0px"};
  padding: ${props => props.padding || "0px"};
`

export const FixedGridElement = styled.div`
  overflow: auto;
`

//migrated
export const OverflowContainerHorizontal = css`
  overflow-x: auto;
  overflow-y: hidden;
`

//migrated
export const OverflowContainerVertical = css`
  overflow-x: hidden;

  overflow-y: auto;
`

//migrated
export const ScrollingListContainer = styled.div`
  ${OverflowContainerVertical}
  padding: 15px;
  border-radius: 10px;
  padding-bottom: 20px;
`

const BorderGridStyle = styled.div`
  display: grid;
  grid-template-columns: 1rem 1fr 1rem;
  grid-template-rows: 1rem 1fr 1rem;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100%;
  ${props => props.$background && `background: ${props.$background}`}
`

export function BorderGrid({children, background}) {
  return (
    <BorderGridStyle $background={background}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      {children}
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </BorderGridStyle>
  )
}
