
export const UKE_HCHS_LOGO = `
<?xml version="1.0" encoding="utf-8"?>
<svg 
  version="1.1" 
  xmlns="http://www.w3.org/2000/svg" 
  xmlns:xlink="http://www.w3.org/1999/xlink" 
  >
  <svg viewBox="0 0 1000 250" preserveAspectRatio="xMinYMin meet">
    <svg x="0" y="0" style="enable-background:new 0 0 600 450;" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <style type="text/css">
	.st0{fill:#004991;}
	.st1{fill:#FFFFFF;}
	.st2{fill:#878787;}
</style>
  </defs>
  <g transform="matrix(0.47938600182533264, 0, 0, 0.47938600182533264, -73.29817962646484, -33.029720306396484)">
    <polygon class="st0" points="447.4,207.3 447.4,186.7 410.5,186.7 410.5,68.9 152.9,68.9 152.9,326.6 410.5,326.6 410.5,282.4 &#10;&#9;&#9;447.4,282.4 447.4,261.8 410.5,261.8 410.5,244.9 443.7,244.9 443.7,224.3 410.5,224.3 410.5,207.3 &#9;"/>
    <path class="st1" d="M262.8,283.8c-30,0-36.3-16.4-36.3-33.9v-62.9h15.4v61.8c0,12.7,4.2,22.3,21.9,22.3c15.6,0,22.6-6.5,22.6-24.3 v-59.8h15.1v58.7C301.6,271.1,287.9,283.8,262.8,283.8L262.8,283.8z"/>
    <polygon class="st1" points="371.6,282.4 337,235.7 337,282.4 321.5,282.4 321.5,186.9 337,186.9 337,231.2 371.7,186.9 &#10;&#9;&#9;390.7,186.9 352.8,233 392.1,282.4 &#9;"/>
    <polygon class="st2" points="170.1,381.5 170.1,369.3 155.9,369.3 155.9,381.5 152.9,381.5 152.9,355.6 155.9,355.6 155.9,366.8 &#10;&#9;&#9;170.1,366.8 170.1,355.6 173,355.6 173,381.5 &#9;"/>
    <path class="st2" d="M203.1,362.3c-0.4-1.3-0.8-2.4-1.1-3.5h-0.1c-0.3,1.1-0.7,2.2-1.1,3.4l-3.9,10.6h10L203.1,362.3L203.1,362.3z M210.1,381.5l-2.2-6.4h-11.7l-2.3,6.4h-3.1l9.7-25.8h3.3l9.7,25.8H210.1L210.1,381.5z"/>
    <path class="st2" d="M257,381.5l-2.3-16c-0.4-2.9-0.7-5.1-0.9-6.5h-0.1c-0.2,1.1-0.7,2.6-1.3,4.4l-6.2,18.1H243l-5.9-17 c-0.7-2-1.2-3.9-1.7-5.5h0c-0.2,2-0.4,4.2-0.7,6.8l-1.9,15.7h-3.1l3.7-25.8h3.8l6,17.5c0.4,1.3,1,3.3,1.5,5h0 c0.5-1.8,0.9-3.2,1.4-4.7l6.1-17.8h3.9l3.9,25.8L257,381.5L257,381.5z"/>
    <path class="st2" d="M285,369.2c-0.5,0-1.9,0-2.5,0v9.8c0.7,0.1,2.4,0.2,3.6,0.2c4.7,0,6.6-2.3,6.6-5.2 C292.7,370.5,289.6,369.2,285,369.2L285,369.2z M286.2,357.9c-1.3,0-2.6,0-3.7,0.1v9c0.8,0,1.5,0,2.5,0c4.7,0,6.9-1.8,6.9-4.9 C291.8,359.7,290.6,357.9,286.2,357.9L286.2,357.9z M285.7,381.6c-1.9,0-4-0.1-6.2-0.1v-25.8c1.8,0,4.1-0.1,7.2-0.1 c5.6,0,8.2,2.4,8.2,6.1c0,2.8-1.7,5.2-4.7,6.1v0.1c3.3,0.4,5.5,2.7,5.5,6C295.7,377.1,293.5,381.6,285.7,381.6L285.7,381.6z"/>
    <path class="st2" d="M324.8,381.8c-8.1,0-9.7-4.5-9.7-8.9v-17.3h3v17c0,3.7,1.2,6.8,6.9,6.8c4.8,0,7.2-2,7.2-7.5v-16.3h2.9v16 C335.1,378.3,331.5,381.8,324.8,381.8L324.8,381.8z"/>
    <path class="st2" d="M361.3,357.9c-1,0-1.7,0-2.5,0.1v9.3c0.4,0,1.2,0.1,2.4,0.1c4,0,6.4-2,6.4-4.9 C367.5,359.1,365.4,357.9,361.3,357.9L361.3,357.9z M368.5,381.5l-9.2-11.9h-0.5v11.9h-3v-25.8c1.7-0.1,3.4-0.1,6.2-0.1 c4.9,0,8.6,1.7,8.6,6.5c0,4.6-3.8,7.1-8.2,7.3c0.8,0.9,1.7,1.8,2.3,2.7l7.5,9.5H368.5L368.5,381.5z"/>
    <path class="st2" d="M401.9,381.8c-8.1,0-12.9-4.7-12.9-12.7c0-7.9,4.7-13.8,13.9-13.8c2.5,0,4.9,0.4,7.2,1.1l-0.7,2.7 c-2.2-0.8-4.5-1.3-6.9-1.3c-7.4,0-10.4,5.2-10.4,10.9c0,6.8,3.9,10.6,10.2,10.6c1.9,0,3.5-0.3,5.1-0.8v-10.1h3v11.9 C407.8,381.3,404.7,381.8,401.9,381.8L401.9,381.8z"/>
  </g>
</svg>
  </svg>
  <svg viewBox="0 0 1000 250" preserveAspectRatio="xMaxYMin meet">
  <svg x="50%" y="0" xmlns="http://www.w3.org/2000/svg">
  <g transform="matrix(1.013118028640747, 0, 0, 1.013118028640747, -3.043390989303589, -178.2969207763672)">
    <g>
      <rect style="fill: rgb(0, 56, 147); paint-order: stroke; stroke: rgb(0, 56, 147);" x="202" y="214.944" width="15" height="66" rx="1" ry="1"/>
      <rect style="fill: rgb(0, 56, 147); paint-order: stroke; stroke: rgb(0, 56, 147);" x="481.53" y="215.08" width="15" height="66" rx="1" ry="1"/>
      <rect style="fill: rgb(0, 56, 147); paint-order: stroke; stroke: rgb(0, 56, 147);" x="3.004" y="215.15" width="15" height="66" rx="1" ry="1"/>
    </g>
    <g>
      <text style="fill: rgb(0, 60, 149); font-family: Arial, sans-serif; font-size: 7.2px; white-space: pre;" transform="matrix(4.980847, 0, 0, 4.565359, -970.752686, -789.530029)"><tspan x="242.777" y="224.965">Hamburg City</tspan><tspan x="242.77699279785156" dy="1.3em">​</tspan><tspan>Health  Study</tspan><tspan x="242.77699279785156" dy="1.3em">​</tspan><tspan x="242.77699279785156" dy="1.3em">​</tspan></text>
    </g>
    <g>
      <rect style="fill: rgb(225, 0, 72); stroke: rgb(225, 0, 72);" x="125.525" y="183.608" width="5.188" height="135.279" rx="1" ry="1" transform="matrix(0.913547, 0.406734, -0.406734, 0.913547, 104.15554, -27.515139)"/>
      <rect style="fill: rgb(0, 122, 205); stroke: rgb(0, 122, 205);" x="125.525" y="183.608" width="5.188" height="135.279" rx="1" ry="1" transform="matrix(0.998027, 0.062788, -0.062788, 0.998027, 11.509869, -7.548846)"/>
      <rect style="fill: rgb(0, 56, 149); stroke: rgb(0, 56, 149);" x="125.525" y="183.608" width="5.188" height="135.279" rx="1" ry="1" transform="matrix(0.981627, -0.190811, 0.190811, 0.981627, -76.114807, 29.232239)"/>
      <rect style="fill: rgb(0, 56, 149); stroke: rgb(0, 56, 149);" x="125.525" y="186.23" width="5.188" height="137.211" rx="1" ry="1" transform="matrix(0.961261, -0.27564, 0.27564, 0.961261, -74.599902, 38.298868)"/>
      <rect style="fill: rgb(0, 56, 149); stroke: rgb(0, 56, 149);" x="125.525" y="183.608" width="5.188" height="135.279" rx="1" ry="1" transform="matrix(0.355104, -0.934827, 0.934827, 0.355104, -172.668261, 286.386341)"/>
      <rect style="fill: rgb(0, 122, 205); stroke: rgb(0, 122, 205);" x="125.525" y="183.608" width="5.188" height="135.279" rx="1" ry="1" transform="matrix(0.53435, -0.845263, 0.845263, 0.53435, -163.154236, 207.692117)"/>
      <rect style="fill: rgb(225, 0, 72); stroke: rgb(225, 0, 72);" x="125.525" y="183.608" width="5.188" height="135.279" rx="1" ry="1" transform="matrix(0.185668, 0.982613, -0.982613, 0.185668, 331.723431, 68.827247)"/>
      <rect style="fill: rgb(225, 0, 72); stroke: rgb(225, 0, 72);" x="125.525" y="186.702" width="5.188" height="137.559" rx="1" ry="1" transform="matrix(0.627962, -0.778244, 0.778244, 0.627962, -173.089806, 192.000903)"/>
      <rect style="fill: rgb(0, 122, 205); stroke: rgb(0, 122, 205);" x="125.525" y="183.608" width="5.188" height="135.279" rx="1" ry="1" transform="matrix(0.953455, 0.301535, -0.301535, 0.953455, 51.594761, -31.7939)"/>
      <rect style="fill: rgb(0, 56, 149); stroke: rgb(0, 56, 149);" x="125.525" y="183.608" width="5.188" height="135.279" rx="1" ry="1" transform="matrix(0.612909, 0.790154, -0.790154, 0.612909, 230.094166, -3.89692)"/>
      <rect style="fill: rgb(0, 56, 149); stroke: rgb(0, 56, 149);" x="125.525" y="183.608" width="5.188" height="135.279" rx="1" ry="1" transform="matrix(0.151263, 0.988494, -0.988494, 0.151263, 340.269506, 92.724462)"/>
    </g>
  </g>
</svg>
</svg>
</svg>
`

