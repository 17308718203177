import { hasNumberOfUnsetArguments, isFunction, isOneOf, isString } from "typescript_helpers";

export class StructureElement {
  constructor(){
    this.metatype = 'structureElement';
  }

  isStructureElement(){
    return true;
  }
}

export class ContentElement extends StructureElement {
  constructor(content_key){
    super();
    this.content_key = content_key;
  }

  getContent(proposal){
    if(!isString(this.content_key)) return null;
    return proposal.getProposalAttribute(this.content_key);
  }
}

//migrated
export class StructureContainer extends StructureElement {
  constructor(elements){
    super();
    this.metatype = 'structureContainer';
    this.elements = elements;
  }
  
  isStructureElement(){
    try{
      for (const element of this.elements){
        if(!element.isStructureElement()) return false;
      }
      return true;
    }
    catch (e){
      return false;
    }
  }
}

//migrated
export class Collection extends StructureContainer{
  constructor(name, elements){
    super(elements);
    this.type = 'collection';
    this.name = name;
  }
}

//migrated
export class Text extends ContentElement{
  constructor(name, content_key){
    super(content_key);
    this.name = name;
    this.type = 'text'
  }
}

//migrated
export class CombinedText extends Text{
  constructor(name, content_keys){
    super(name, null);
    this.content_keys = content_keys;
  }

  getContent(proposal){
    let contents = this.content_keys.map((key)=>{
      return proposal.getProposalAttribute(key)});
    return contents.join(" ");
  }
}

export class SingleChoiceSelection {
  constructor(name, content_key, options, evaluator){
    this.name = name;
    this.content_key = content_key;
    this.options = options;
  }

  getSelectedOption(proposal){

  }
}

//migrated
export class Option {
  constructor(name, content_key, conditional_section, evaluator){
    this.name = name;
    this.content_key = content_key;
    this.conditional_section = conditional_section;
    this.evaluator = evaluator;
    this.type = 'option';
  }

  isSelected(proposal){
    let result = this.evaluator(proposal, this.content_key, this.name);
    console.log(`option name is ${this.name} content_key is ${this.content_key} eval result is ${result}`);
    if(isFunction(this.evaluator) && 
      hasNumberOfUnsetArguments(3, this.evaluator)){
      return this.evaluator(proposal, this.content_key, this.name);
    }
    else {
      return false;
    }
  }
  
  getConditionalSection(proposal){
    if(this.isSelected(proposal)) {
      return this.conditional_section;
    }
    return false;
  }
}

//migrated
export class MultiChoice {
  constructor(name, options){
    this.name = name;
    this.options = this.buildOptions(options);
    this.type = 'multi_choice'
  }

  getSelectedOptions(proposal){
    let result_options = [];
    for (const option of this.options){
      if(option.isSelected(proposal)){
        result_options.push(option);
      }
    }
    return result_options;
  }

  buildOptions(options){
    const defaultEvaluator = (proposal, content_key, name) => {
      try {
        let attribute_value = proposal.getProposalAttribute(content_key);
        return !isOneOf(attribute_value, ['No', 'no', 'false', false, null, ""]);
      }
      catch (err){
        return false;
      }
    }
    let options_elements = [];
    for (const option_data of options){
      options_elements.push(
        new Option(
          option_data.optionName,
          option_data.content_key,
          option_data.conditional_section,
          defaultEvaluator,
        )
      )
    }
    return options_elements;
  }
}

//migrated
export class SingleChoice {
  constructor(name, content_key, options, evaluator){
    this.name = name;
    this.content_key = content_key;
    this.options = this.buildOptions(content_key, options, evaluator);
    this.evaluator = evaluator;
    this.type = 'single_choice';
  }

  buildOptions(content_key, options, evaluator){
    let options_elements = [];
    for (const option_data of options){
      let option = new Option(option_data.optionName, content_key, option_data.optionConditionalContent, evaluator);
      options_elements.push(option);
    }
    return options_elements
  }

  getSelectedOption(proposal){
    for (const option of this.options){
      if(option.isSelected(proposal)) return option;
    }
  }
}

//migrated
export class Subcollection {
  constructor(name, sections){
    this.name = name;
    this.sectios = sections;
    this.type = 'subcollection';
  }
}

//migrated
export class NamelessGrouping {
  constructor(sections){
    this.type = 'grouping';
    this.sections = sections;
  }
}

