import styled from "styled-components";
export const MenuAreaFormWrapper = styled.div `
    background: linear-gradient(90deg, #f00, #fff);
    position: relative;
    height: 600px;
    width: 360px;
    box-shadow: 0px 0px 20px 15px rgba(255, 255, 255, 0.8);
    border-radius: 0px;
    overflow: hidden;
`;
