import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { BlueFrameBoxTiny } from "../../Borders/BlueFrameBoxes/BlueFrameBoxTiny";
export const Header = styled.h5 `
  word-wrap: break-word;
  width: fit-content;
  padding: 0.5rem;
  margin: 0.5em 0.5em;
  justify-self: center;
  ${BlueFrameBoxTiny}
`;
export function HeaderTiny({ text }) {
    return (_jsx(Header, { children: text }));
}
