import styled from "styled-components"
import { ColumnGrid, FixedGridElement, RowGrid } from "../divider and frames/layouts"
import { RedDividerBar } from "../divider and frames/divider_bars"
import { ResetForm } from "../forms/password_reset_form"
import { Header, Indent } from "../text_display/main_content_text_styling"
import { BlueFrameBox } from "../divider and frames/frame_boxes"
import { BorderSolidLightBlue, BorderSolidSlimBlack, PaddedBorderSlimBlack } from "../themes and styles/borders"
import { Bold, Oblique, UnderlinedBold } from "../themes and styles/text_styles"
import { useEffect, useState } from "react"
import { Proposal } from "../../nonComponentFunctions/Proposal"
import { useAuth } from "../../authentication/auth_provider"
import { TestCollapsible } from "../widgets/collapsible"
import Collapsible from "react-collapsible";
import { isOneOf } from "typescript_helpers"
import { Dropdown } from "../widgets/dropdown_menu"
import { DownloadProposalButton } from "../buttons/menu_area_buttons"
import { InputElementButtonContainer } from "../text_inputs/main_content_inputs"
import {Pagination} from "../widgets/pagination"

//migrated
export function LineItem({ title, text, align }) {
  return (
    <RowGrid rowGap={"0.2rem"}>
      <UnderlinedBold>{title}</UnderlinedBold>
      <Indent margin_left={"1rem"} style={align || { textAlign: "justify" }}>
        <Oblique>
          {text}
        </Oblique>
      </Indent>
    </RowGrid>
  )
}

//migrated
export function LineHalf(props) {
  return (
    <ColumnGrid
      template_columns={"repeat(auto-fit, minmax(150px, 1fr))"}
      row_gap={"1rem"}
      column_gap={"1rem"}
    >
      {props.children}
    </ColumnGrid>
  )
}

const StyledGrid = styled(ColumnGrid)`
  
`

//migrated
function ProposalItem({ title, text }) {
  if (!isOneOf(text, [null, undefined, ""])) {
    return (
      <LineItem title={title} text={text} />
    )
  }
  return <LineItem title={title} text={"n/a"} />
}

function ProposalStatus({proposal_base}){
  const {mail, token} = useAuth();
  let status = proposal_base.getProposalAttribute('status');
  if(isOneOf(status, [null, ""])){
    status = "unknown";
  }

  const [loading, setLoading] = useState(false);

  const handleOptionSelection = async (option)=>{
    setLoading(true);
      proposal_base.setProposalAttribute('status', option);
      try{
        let proposal = await proposal_base.db_set_status(mail, token);
        setLoading(false);
      }
      catch (err){
        setLoading(false);
      }
  }

  return (
    <LineItem title={"Status"} text={
      (! loading &&
        <Dropdown 
        setSelectionCallback={handleOptionSelection}
        defaultSelection={status}
        MenuOptions={['unknown', 'preparing proposal', 'proposal issued', 'proposal accepted', 'proposal denied', 'proposal in revision']}/>
      ) || "loading" 
      } />
    
    )
}

//migrated
const ProposalExpander = styled.div`
  &:hover {
    cursor: pointer;
    background-color: rgba(12,12,12,0.2);
    border-radius: 1rem;
  }
`

//migrated
function ProposalSectionNeededData(proposal) {
  return <>
    <RedDividerBar />
    <ProposalItem title={"Outcome"} text={proposal.getProposalAttribute('methods_outcome')} />
    <ProposalItem title={"Exposure"} text={proposal.getProposalAttribute('methods_description')} />
    <ProposalItem title={"Confunder"} text={proposal.getProposalAttribute('methods_confounder')} />
  </>
}

//migrated
function ProposalSectionFurtherInformation(proposal) {
  return <>
    <RedDividerBar />
    <StyledGrid
      template_columns={"repeat(auto-fit, minmax(200px, 1fr))"}
      row_gap={"1rem"}
      column_gap={"2rem"}>
      <ProposalItem title={"Regulation of IP rights"} text={proposal.getProposalAttribute('other_ip_rights')} />
      <ProposalItem title={"Participating Partners"} text={proposal.getProposalAttribute('other_partners')} />
      <ProposalItem title={"Financial share"} text={proposal.getProposalAttribute('other_financial')} />
      <ProposalItem title={"Manuscript including authors"} text={proposal.getProposalAttribute('other_manuscript')} />
    </StyledGrid>
  </>
}

//migrated
function ProposalSectionBiomaterials(proposal) {
  return <>
    <RedDividerBar />
    <ProposalItem title={"Are Biomaterials needed?"} text={
      proposal.areBiomaterialsNeeded() ? 'yes' : 'no'} />
    {proposal.areBiomaterialsNeeded() &&
      <StyledGrid
        template_columns={"repeat(auto-fit, minmax(200px, 1fr))"}
        row_gap={"1rem"}
        column_gap={"2rem"}>
        <ProposalItem title={"Kind of biomaterials needed"} text={proposal.getProposalAttribute('biomaterial_which')} />
        <ProposalItem title={"Total number of needed materials"} text={proposal.getProposalAttribute('biomaterial_number')} />
        <ProposalItem title={"Is use of previous project leftovers possible?"} text={proposal.getProposalAttribute('biomaterial_leftover')} />
        <ProposalItem title={"Volume?"} text={proposal.getProposalAttribute('biomaterial_volume')} />
        <ProposalItem title={"Markers to be measured"} text={proposal.getProposalAttribute('biomaterial_marker')} />
        <ProposalItem title={"Which procedure is needed to measure the marker?"} text={proposal.getProposalAttribute('biomaterial_procedure')} />
        <ProposalItem title={"Are there multiple determinations per propband necessary?"} text={proposal.getProposalAttribute('biomaterial_multiple')} />
        <ProposalItem title={"Where do the measurements take place?"} text={proposal.getProposalAttribute('biomaterial_where')} />
        <ProposalItem title={"Financial share of measurements"} text={proposal.getProposalAttribute('biomaterial_financial')} />
        <ProposalItem title={"Shipment necessary? If yes, to whom?"} text={proposal.getProposalAttribute('biomaterial_shipment')} />
        <ProposalItem title={"Transport Company"} text={proposal.getProposalAttribute('biomaterial_transp_company')} />
        <ProposalItem title={"Can biomaterials be sent back?"} text={proposal.getProposalAttribute('biomaterial_return')} />
      </StyledGrid>
    }
  </>
}

//migrated
function ProposalSectionMethods(proposal) {
  return <>
    <RedDividerBar />
    <StyledGrid
      template_columns={"repeat(auto-fit, minmax(200px, 1fr))"}
      row_gap={"1rem"}
      column_gap={"2rem"}>

      <ProposalItem title={"Strategies regarding missing variables"} text={proposal.getProposalAttribute('analysis_missing_variables')} />
      <ProposalItem title={"Description of statistical methods"} text={proposal.getProposalAttribute('analysis_statistical_methods')} />
      <ProposalItem title={"Description of methods to examine subgroups"} text={proposal.getProposalAttribute('analysis_exam_methods')} />
      <ProposalItem title={"Description of sensitivity analysis"} text={proposal.getProposalAttribute('analysis_sensitivity_analyses')} />
      <ProposalItem title={"Strategies regarding missing data"} text={proposal.getProposalAttribute('analysis_missing_data')} />
      <ProposalItem title={"How is loss of follow-up addressed"} text={proposal.getProposalAttribute('analysis_loss_fu')} />
      <ProposalItem title={"Type of presentation"} text={proposal.getProposalAttribute('analysis_presentation_type')} />
    </StyledGrid>
  </>
}

//migrated
function ProposalSectionCohort(proposal) {
  return <>
    <RedDividerBar />
    <StyledGrid
      template_columns={"repeat(auto-fit, minmax(200px, 1fr))"}
      row_gap={"1rem"}
      column_gap={"2rem"}>

      <ProposalItem title={"Definition of Cohort exclusion"} text={proposal.getProposalAttribute('analysis_exclusion')} />
      <ProposalItem title={"Definition of Cohort inclusion"} text={proposal.getProposalAttribute('analysis_inclusion')} />
      <ProposalItem title={"Definition of Cohort subgroups"} text={proposal.getProposalAttribute('analysis_subgroups')} />
    </StyledGrid>
  </>
}

//migrated
function ProposalSectionTimelineAndBias(proposal) {
  return <>
    <RedDividerBar />
    <StyledGrid
      template_columns={"repeat(auto-fit, minmax(200px, 1fr))"}
      row_gap={"1rem"}
      column_gap={"2rem"}>
      <ProposalItem title={"Estimated Timeline"} text={proposal.getProposalAttribute('methods_timeline')} />
      <ProposalItem title={"Bias and Limitations"} text={proposal.getProposalAttribute('methods_bias_limitations')} />
    </StyledGrid>
  </>
}

//migrated
function ProposalSectionSetting(proposal) {
  return <>
    <RedDividerBar />
    <StyledGrid
      template_columns={"repeat(auto-fit, minmax(200px, 1fr))"}
      row_gap={"1rem"}
      column_gap={"2rem"}>
      <>

        <ProposalItem title={"Setting baseline"} text={
          proposal.hasSettingBaseline() ? 'is selected' : 'not selected'} />
        <ProposalItem title={"Setting yearly questionaire"} text={
          proposal.hasSettingYearly() ? 'is selected' : 'not selected'} />
        {
          proposal.hasSettingYearly() &&
          <ProposalItem title={"Yearly questioraire year indication"} text={proposal.getProposalAttribute('methods_setting_yearly_year')} />
        }
      </>
      <>
        <ProposalItem title={"Setting MRI"} text={
          proposal.hasSettingMRI() ? 'is selected' : 'not selected'} />
        {
          proposal.hasSettingMRI() &&
          <>
            <ProposalItem title={"MRI cardio"} text={
              proposal.hasSettingMRIcardio() ? 'is selected' : 'not selected'} />
            <ProposalItem title={"MRI neuro"} text={
              proposal.hasSettingMRIneuro() ? 'is selected' : 'not selected'} />
          </>
        }
      </>
      {
        proposal.hasSettingOther() &&
        <>
          <ProposalItem title={"Setting other"} text={proposal.getProposalAttribute('methods_setting_other')} />
          <ProposalItem title={"Setting other explaination"} text={proposal.getProposalAttribute('methods_setting_other_statement')} />
        </>
      }
    </StyledGrid>
  </>
}

//migrated
function ProposalSectionSize(proposal) {

  const sample_size = proposal.getProposalAttribute('methods_sample_size');

  return <>
    <RedDividerBar />
    <StyledGrid
      template_columns={"repeat(auto-fit, minmax(200px, 1fr))"}
      row_gap={"1rem"}
      column_gap={"2rem"}>

      <ProposalItem title={"Sample size"} text={proposal.isSampleSizeNonStandard() ? "non standard sample size" : sample_size} />
      {proposal.isSampleSizeNonStandard() &&
        <ProposalItem title={"Sample size exception"} text={proposal.getProposalAttribute('methods_sample_size_exception')} />
      }
      <ProposalItem title={"Power calculation"} text={proposal.getProposalAttribute('methods_power_calc')} />
    </StyledGrid>
  </>
}

//migrated
function ProposalSectionDesign(proposal) {
  return <>
    <RedDividerBar />
    <StyledGrid
      template_columns={"repeat(auto-fit, minmax(200px, 1fr))"}
      row_gap={"1rem"}
      column_gap={"2rem"}>
      <ProposalItem title={"Design"} text={proposal.getProposalAttribute('methods_design')} />
      {
        proposal.hasProspectiveFollowUp() &&
        <ProposalItem title={"Prospective with follow-up"} text={proposal.getProposalAttribute('methods_design_prosp')} />
      }
      {
        proposal.hasDesignOther() &&
        <ProposalItem title={"Other design"} text={proposal.getProposalAttribute('methods_design_other')} />
      }
    </StyledGrid>
  </>
}

//migrated
function ProposalSectionMainInfo(proposal) {
  return <>
    <ProposalItem title={"Project background"} text={proposal.getProposalAttribute('intro_background')} />
    <ProposalItem title={"Project objectives"} text={proposal.getProposalAttribute('intro_objectives')} />
    <ProposalItem title={"Overlap to other projects"} text={proposal.getProposalAttribute('intro_overlap')} />
    <ProposalItem title={"Project novelty"} text={proposal.getProposalAttribute('intro_novelty')} />
  </>
}

//migrated
function ProposalEntry({ proposal_base }) {
  const { mail, token } = useAuth();
  const [proposal, setProposal] = useState(null);
  const [show, setShow] = useState(false);

  const openLoadedProposal = (proposal) => {
    setProposal(proposal);
  }

  useEffect(() => { proposal !== null && setShow(true) }, [proposal])

  const handleClick = () => {
    if (show) {
      setShow(false);
      return;
    }
    if (proposal == null) {
      fetch_full_data();
      return;
    }
    setShow(true);
  }

  const fetch_full_data = async () => {
    const proposal = await proposal_base.db_get_proposal_data(mail, token);
    openLoadedProposal(proposal);
  }

  return (
    <>
      <PaddedBorderSlimBlack >
        <StyledGrid
          template_columns={"repeat(auto-fit, minmax(100px, 1fr))"}
          row_gap={"1rem"}
          column_gap={"2rem"}>
          <LineHalf>
            <ProposalExpander onClick={handleClick}>
            <LineItem
              align={{ textAlign: "left" }}
              title={"Title"} text={proposal_base.getProposalAttribute('intro_project_title')} />
              </ProposalExpander>
          </LineHalf>
          <LineHalf>
            <LineItem title={"Author"} text={`${proposal_base.getProposalAttribute('intro_applicant_firstname')} ${proposal_base.getProposalAttribute('intro_applicant_lastname')}`} />
            <LineItem title={"Date"} text={proposal_base.getProposalAttribute('date')} />
            <ProposalStatus proposal_base={proposal_base} />
          </LineHalf>
        </StyledGrid>
      </PaddedBorderSlimBlack>
      {show && proposal !== null && <Indent margin_left={"2rem"} >

        <StyledGrid style={{ marginRight: "2rem" }}
          template_columns={
            //"repeat(auto-fit, minmax(300px, 1fr))"
            "1fr"
          }
          row_gap={"1rem"}
          column_gap={"2rem"}>
          {ProposalSectionMainInfo(proposal)}
          {ProposalSectionNeededData(proposal)}
          {ProposalSectionDesign(proposal)}
          {ProposalSectionSize(proposal)}
          {ProposalSectionSetting(proposal)}
          {ProposalSectionTimelineAndBias(proposal)}
          {ProposalSectionCohort(proposal)}
          {ProposalSectionMethods(proposal)}
          {ProposalSectionBiomaterials(proposal)}
          {ProposalSectionFurtherInformation(proposal)}
          <InputElementButtonContainer>
            <DownloadProposalButton proposal={proposal}></DownloadProposalButton>
          </InputElementButtonContainer>
          <div></div>
        </StyledGrid>

      </Indent>}
    </>
  )
}

export function ProposalRenderer(props) {
  const { mail, token } = useAuth();

  const proposalsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);

  const [proposals, setProposals] = useState([]);

  const fetch_proposals = async () => {
    const proposals = await Proposal.db_get_all_proposals(mail, token);
    setProposals(proposals);
  }

  useEffect(() => {
    fetch_proposals();
  }, [])

  const indexOfLastProposal = currentPage * proposalsPerPage;
  const indexOfFirstProposal = indexOfLastProposal - proposalsPerPage;
  const currentProposals = proposals.slice(indexOfFirstProposal, indexOfLastProposal);

  return (
    <>
      <Header text={"Issued Proposals"} />
      <BlueFrameBox>
        <RowGrid
          rowGap={"1rem"}
          padding={"1rem 0px"}>
          <Pagination 
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalElements={proposals.length}
            elementsPerPage={proposalsPerPage} />
          {currentProposals.map((proposal, index) => {
            return (
              <ProposalEntry
                key={index}
                proposal_base={proposal} />
            )
          })}
        </RowGrid>
      </BlueFrameBox>
    </>
  )
}
