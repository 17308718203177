import { InfoMenu } from "./widgets/inlo_options_menu"
import { OverlayContentModal } from "./modals/overlay_content_modal"
import { SorterPanel, VariableSorter } from "./variablenmanual/variable_sorter"
import { Header, Indent } from "./text_display/main_content_text_styling"
import { useAuth } from "../authentication/auth_provider"
import { useEffect } from "react"
import { Proposal } from "../nonComponentFunctions/Proposal"
import { useState } from "react"
import { BlueFrameBox } from "./divider and frames/frame_boxes"
import { ContextLinkStyling } from "./variablenmanual/vmlink"
import { test_jwt, test_requestor_mail } from "../nonComponentFunctions/test_config"
import { BorderSolidSlimBlack, PaddedBorderSlimBlack } from "./themes and styles/borders"
import { OverflowContainerVertical, RowGrid } from "./divider and frames/layouts"
import { PanelBorderStyling } from "./variablenmanual/panel_styling"
import styled from "styled-components"
import { InputElementButtonContainer } from "./text_inputs/main_content_inputs"
import { DownloadProposalButton, Submit } from "./buttons/menu_area_buttons"
import { LineItem } from "./proposals/issued_proposals"
import { usePageLayout } from "./page_layouts"
import { FormElementNeededData } from "./proposals/issue_proposal_form_elements/FormElementNeededData"
import Logo from "../img/hchs_logo.svg";
import ULogo from "../img/uke-seeklogo.com-3.svg";
import UKE_HCHS_Logo from "../img/uke_hchs_logo.svg";
import {UKE_PDF} from "../nonComponentFunctions/pdf_generation/pdf_generation";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { collection_methodology, single_choice_hchs_cohort } from "../nonComponentFunctions/proposal_structure/collection_methodology"
import { Text } from "../nonComponentFunctions/proposal_structure/structuring_elements"
import { TestProposal } from "../nonComponentFunctions/test_proposal"
import { proposal_structure } from "../proposal_structuring"
import { MenuAreaButtonIconLeft } from "./buttons/button_styling"
pdfMake.vfs = pdfFonts.pdfMake.vfs;


const pdf = new UKE_PDF("test_name", [
  proposal_structure
]);

export const Test = () => {
  useEffect(()=>{
  },[])
  return (
    <>
      <DownloadProposalButton proposal={TestProposal}/>
      <div>das ist ein test
        <img src={UKE_HCHS_Logo} />
      </div>
          </>
  )
}
