import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "../../authentication/auth_provider";
import { Proposal } from "../../nonComponentFunctions/Proposal";
import { Submit } from "../buttons/menu_area_buttons";
import { RedDividerBar } from "../divider and frames/divider_bars";
import { BlueFrameBox } from "../divider and frames/frame_boxes";
import { Header, Header2, LabelSmall, NoticeText, OptionalInputIndent } from "../text_display/main_content_text_styling";
import { 
  InputElementButtonContainer,
  InputElementCheckbox, 
  InputElementContainerSideBySidePositioning,
  InputElementContainerTopDownPositioning,
  InputElementSingleChoiceRadio, 
  InputItem, 
  OptionalTextArea,
  InputElementMultilineTextArea, 
} from "../text_inputs/main_content_inputs";
import { FormElementNeededData } from "./issue_proposal_form_elements/FormElementNeededData";
import { ProposalSubmitModal } from "./proposal_submit_modal";
import styled from "styled-components";


export function IssueProposalFormDev({mode}){
  
  const [iter, setIter] = useState(0);
  const [form, setForm] = useState(null);
  const resetForm = ()=>{
    setIter(iter + 1);
  }

  useEffect(()=>{
    resetForm();
  }, []);

  useEffect(()=>{
    setForm(<ProposalForm  iter={iter+1} mode={mode} navigate={navigate} resetCallback={resetForm} />);
  }, [iter]);
  
  const navigate = useNavigate();
  return (
    <>
      {form}
    </>
  )
}

const FormHeader = styled(Header2)`
  text-align: center;
`

function ProposalForm({  iter, mode, resetCallback}) {
  const [modal_show, set_modal_show] = useState(false);
  const [proposal, setProposal] = useState(new Proposal());

  const submit_callback = ()=>{
    set_modal_show(true);
  }
  const update_proposal = (attribute_id, val) => {
    proposal.setProposalAttribute(attribute_id, val);
  }
  const changeFunction = (event, attribute_id) => {
    update_proposal(attribute_id, event.target.value);
  }

  return <>
    {modal_show &&
      <ProposalSubmitModal
        iter={iter}
        proposal={proposal}
        closeModalCallback={() => { resetCallback(); set_modal_show(false) } }
        mode={mode} />}

    <Header text={"Issue Proposal"} />
    <BlueFrameBox iter={iter}>
      {FormElementIntroSection(changeFunction)}
      <RedDividerBar />
      <FormElementMethodology changeFunction={changeFunction}/>
      <RedDividerBar />
      <FormSectionAnalysisPlan changeFunction={changeFunction} proposal={proposal} mode={mode}/>
      <RedDividerBar />
      <FormElementBiomaterials changeFunction={changeFunction} />
      <RedDividerBar />
      <FormSectionFurtherInformation changeFunction={changeFunction} />
      <InputElementButtonContainer>
        <Submit button_text={"Submit"} callback={submit_callback} />
      </InputElementButtonContainer>
    </BlueFrameBox>
  </>
}

function FormElementIntroSection(changeFunction, mode, iter){
  return (
    <>
      <FormHeader>Introduction</FormHeader>
      <InputElementContainerSideBySidePositioning label={"Project Title"}>
        <InputItem  iter={iter}>
          <input
            onChange={(event) => { changeFunction(event, "intro_project_title"); } }
            type="text" placeholder="Project Title"></input>
        </InputItem>
      </InputElementContainerSideBySidePositioning>
      {FormElementAuthorDetails(changeFunction, mode, iter)}
      <InputElementContainerTopDownPositioning label={"Background / Rational / Prior Data"}>
        <NoticeText>
          Explain the scientific background and rational for the investigation being reported
        </NoticeText>
        <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "intro_background"); } } />
      </InputElementContainerTopDownPositioning>
      <InputElementContainerSideBySidePositioning label={"Objective(s) / Hypotheses"}>
        <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "intro_objectives"); } } />
      </InputElementContainerSideBySidePositioning>
      <InputElementContainerSideBySidePositioning label={"Known Content Overlap With Other Proposals?"}>
        <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "intro_overlap"); } } />
      </InputElementContainerSideBySidePositioning>
      {/* <InputElementContainerSideBySidePositioning label={"Novelty of the project"}>
        <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "intro_novelty"); } } />
      </InputElementContainerSideBySidePositioning>*/}

    </>
  )
}

function FormElementAuthorDetails(changeFunction, mode, iter) {
  const { role } = useAuth();

  return <InputElementContainerSideBySidePositioning iter={iter} label={"Applicant / Coordination Investigator"}>
    <InputItem>
      <input
        onChange={(event) => { changeFunction(event, "intro_applicant_firstname"); } }
        type="text"
        placeholder="first name"></input>
    </InputItem>
    <InputItem>
      <input
        onChange={(event) => { changeFunction(event, "intro_applicant_lastname"); } }
        type="text" placeholder="last name"></input>
    </InputItem>
    { (role === 'admin' || mode) === 'nachtragen' && 
      <InputItem>
        <input 
          onChange={(event) => { changeFunction(event, "USER"); }}
          type="text" placeholder="email"
          />
      </InputItem>
    }
  </InputElementContainerSideBySidePositioning>;
}

function FormElementSettingSubOthers({changeFunction}){
  const box1_label = "Others";

  const [box1_selected, setBox1_selected] = useState(false);

  useEffect(()=>{
    changeFunction({target: {value: box1_selected}}, "methods_setting_other");
  }, [box1_selected])

  return (
    <>
    <InputItem>
      <InputElementCheckbox 
        label={box1_label}
        checked={box1_selected}
        type={"checkbox"}
        onChange={(event)=>{
          setBox1_selected(!box1_selected);
        }}
        />
    </InputItem>
    {box1_selected && 
      <OptionalTextArea 
        placeholder={"Statement"} 
        onChangeCallback={(event)=>{changeFunction(event, "methods_setting_other_statement")}}/>
      }  
    </>
  )
}

function FormSectionFurtherInformation({changeFunction}){
  const [hasPartners, setHasPartners] = useState(false);
  const [hasFunding, setHasFunding] = useState(false);

  const updateHasPartners = (id) => {
    setHasPartners(id === "Yes")
  }

  const updateHasFunding = (id) => {
    setHasFunding(id === "Yes")
  }

  return (
    <>
      <FormHeader>Further Information</FormHeader>
        <InputElementContainerTopDownPositioning label={"Internal / External Cooperation Partners"}>
          <InputElementSingleChoiceRadio box_selected_callback={(id)=>{updateHasPartners(id)}}
            label_array={["Yes", "No"]} />
            {hasPartners && <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "other_partners"); } } />
}
        </InputElementContainerTopDownPositioning>
        <InputElementContainerTopDownPositioning label={"Preliminary List Of Authors For Publication"}>
          <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "other_manuscript") } } />
      </InputElementContainerTopDownPositioning>
    </>
  )
}

function FormSectionAnalysisPlan({changeFunction, proposal, mode}){
  return (
  <>
      <FormHeader>Plan of Analysis</FormHeader>
      {FormElementCohortDefinition(changeFunction)}
      {FormElementNeededData(changeFunction, ((mode === "preselected_variables") && null) || proposal.getProposalAttribute('id'))}
      <InputElementContainerSideBySidePositioning label={"Handling Of Missing Variables"}>
        <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "analysis_missing_variables"); } } />
      </InputElementContainerSideBySidePositioning>
      <FormElementPlanedAnalysis changeFunction={changeFunction}/>
      <InputElementContainerSideBySidePositioning label={"Planned Type of Publication"}>
        <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "analysis_presentation_type"); } } />
      </InputElementContainerSideBySidePositioning>

  </>
  )
}

function FormElementMethodology({changeFunction}){
 return (
    <>
      <FormHeader>Methodology</FormHeader>
      <FormElementSetting changeFunction={changeFunction}/>
      <FormElementSampleSize changeFunction={changeFunction}/>
      <InputElementContainerSideBySidePositioning label={"POWER CALCULATION"}>
        <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "methods_power_calc"); } } />
      </InputElementContainerSideBySidePositioning>
      <InputElementContainerTopDownPositioning label={"Bias and Limitation"}>
        <NoticeText>
          Describe any efforts to address potential sources of bias. Also discuss limitations of the study, taking into account sources of potential bias or imprecision. Discuss both direction and magnitude of any potential bias
        </NoticeText>
        <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "methods_bias_limitations"); } } />
      </InputElementContainerTopDownPositioning>
      <InputElementContainerSideBySidePositioning label={"Estimated Timeline"}>
        <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "methods_timeline"); } } />
      </InputElementContainerSideBySidePositioning>

    </>
  )
}

function FormElementMRISubSelectionTarget({changeFunction}){
  const box1_label = "cardio";
  const box2_label = "neuro";

  const [box1_selected, setBox1_selected] = useState(false);
  const [box2_selected, setBox2_selected] = useState(false);

  useEffect(()=>{
    changeFunction({target: {value: box1_selected}}, "methods_setting_mri_cardio");
  }, [box1_selected])

  useEffect(()=>{
    changeFunction({target: {value: box2_selected}}, "methods_setting_mri_neuro");
  }, [box2_selected])
  
  return (
    <OptionalInputIndent>
      <LabelSmall>Select kind of MRI examination</LabelSmall>
      <InputItem>
        <InputElementCheckbox 
          label={box1_label}
          checked={box1_selected}
          type={"checkbox"}
          onChange={(event)=>{
            setBox1_selected(!box1_selected);
          }}
          />
        <InputElementCheckbox 
          label={box2_label}
          checked={box2_selected}
          type={"checkbox"}
          onChange={(event)=>{
            setBox2_selected(!box2_selected);
          }}
          />
      </InputItem>
    </OptionalInputIndent>
  )
}

function FormElementMRISubSelection({changeFunction}){
  const box1_label = "MRI";

  const [box1_selected, setBox1_selected] = useState(false);

  useEffect(()=>{
    changeFunction({target: {value: box1_selected}}, "methods_setting_mri");
  }, [box1_selected])

  return (
    <>
    <InputItem>
      <InputElementCheckbox 
        label={box1_label}
        checked={box1_selected}
        type={"checkbox"}
        onChange={(event)=>{
          setBox1_selected(!box1_selected);
        }}
        />
    </InputItem>
    {box1_selected && 
        <FormElementMRISubSelectionTarget changeFunction={changeFunction}/>
      }  
    </>
  )

}

function FormElementSettingSubQuestionaire({changeFunction}){
  const box1_label = "Baseline";
  const box2_label = "Yearly Questionaire";

  const [box1_selected, setBox1_selected] = useState(false);
  const [box2_selected, setBox2_selected] = useState(false);

  useEffect(()=>{
    changeFunction({target: {value: box1_selected}}, "methods_setting_baseline");
  }, [box1_selected])

  useEffect(()=>{
    changeFunction({target: {value: box2_selected}}, "methods_setting_yearly");
  }, [box2_selected])

  return (
    <>
    <InputItem>
      <InputElementCheckbox 
        label={box1_label}
        checked={box1_selected}
        type={"checkbox"}
        onChange={(event)=>{
          setBox1_selected(!box1_selected);
        }}
        />
      <InputElementCheckbox 
        label={box2_label}
        checked={box2_selected}
        type={"checkbox"}
        onChange={(event)=>{
          setBox2_selected(!box2_selected);

        }}
        />
      
    </InputItem>
    {box2_selected && 
        <OptionalTextArea 
          placeholder={"Indicate Year"} 
          onChangeCallback={(event)=>{changeFunction(event, "methods_setting_yearly_year")}}/>
      }  
    </>
  )
}

function FormElementSetting({changeFunction}){

  return (
    <InputElementContainerTopDownPositioning label={"Setting"}>
      <FormElementSettingSubQuestionaire changeFunction={changeFunction}/>
      <FormElementMRISubSelection changeFunction={changeFunction}/>
      <FormElementSettingSubOthers changeFunction={changeFunction}/>
    </InputElementContainerTopDownPositioning>
  )
}

function FormElementStudyDesignSubSelection({changeFunction}){
  const box1_label = "Personal Follow-up";
  const box2_label = "Revisit Questionnaire";
  
  const [box1_selected, setBox1_selected] = useState(false);
  const [box2_selected, setBox2_selected] = useState(false);

  const callCallbackWithDefinedValues = () => {
    let val = ""
    if (box1_selected) val += box1_label;
    if (box2_selected) val += "; " + box2_label;
    changeFunction({target: {value: val}}, "methods_design_prosp");
  }

  useEffect(()=>{
    callCallbackWithDefinedValues();
  }, [box1_selected, box2_selected])
  
  return (
    <OptionalInputIndent>
      <LabelSmall>Select Kind Of Follow-Up</LabelSmall>
      <InputItem>
        <InputElementCheckbox 
          label={box1_label}
          checked={box1_selected}
          type={"checkbox"}
          onChange={(event)=>{
            setBox1_selected(!box1_selected);
          }}
          />
        <InputElementCheckbox 
          label={box2_label}
          checked={box2_selected}
          type={"checkbox"}
          onChange={(event)=>{
            setBox2_selected(!box2_selected);
          }}
          />
      </InputItem>
    </OptionalInputIndent>
  )
}

function FormElementSampleSize({changeFunction}) {
  const [activeLabel, setActiveLabel] = useState("");

  useEffect(()=>{
    changeFunction({target: {value: activeLabel}}, "methods_sample_size");
  },[activeLabel]);

  return <InputElementContainerTopDownPositioning label={"HCHS Cohort"}>
    <InputElementSingleChoiceRadio
      box_selected_callback={(label)=>{setActiveLabel(label)}}
      label_array={[
        "10.000",
        "17.000",
        "post-COVID",
        "Exceptions",
      ]} />
      {
        activeLabel === "Exceptions" &&
        
        <OptionalTextArea 
          placeholder={"Indicate Further Information"}
          onChangeCallback={(event)=>{changeFunction(event, "methods_sample_size_exception")}}/>
      
      }
  </InputElementContainerTopDownPositioning>;
}

function FormElementPlanedAnalysis({changeFunction}) {
  return <InputElementContainerTopDownPositioning label={"What Kind Of Analysis Is Planed"}>
    <LabelSmall>Describe All Statistical Methods, Including Those Used To Control For Confounding</LabelSmall>
    <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "analysis_statistical_methods"); } } />
    <LabelSmall>Describe Any Methods Used To Examine Subgroups And Interactions</LabelSmall>
    <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "analysis_exam_methods"); } } />
    <LabelSmall>Explain How Missing Data Were Addressed</LabelSmall>
    <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "analysis_missing_data"); } } />
    <LabelSmall>If Applicable, Explain How Loss To Follow-Up Was Addressed</LabelSmall>
    <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "analysis_loss_fu"); } } />
    <LabelSmall>Describe Any Sensitivity Analyses</LabelSmall>
    <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "analysis_sensitivity_analyses"); } } />
  </InputElementContainerTopDownPositioning>;
}

function FormElementCohortDefinition(changeFunction) {
  return <InputElementContainerTopDownPositioning label={"Definition Of Cohort"}>
    <LabelSmall>Exclusion</LabelSmall>
    <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "analysis_exclusion"); } } />
    <LabelSmall>Inclusion</LabelSmall>
    <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "analysis_inclusion"); } } />
    <LabelSmall>Define Subgroups (if applicable)</LabelSmall>
    <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "analysis_subgroups"); } } />
  </InputElementContainerTopDownPositioning>;
}

function FormElementBiomaterials({changeFunction}) {
  const [activeBoxLabel, updateActiveBoxId] = useState("");
  const [needBio, setNeedBio] = useState(false);

  useEffect(()=>{
    setNeedBio(activeBoxLabel === "Yes");
  },[activeBoxLabel]);

  useEffect(()=>{
    changeFunction({target: {value: needBio}}, "need_bio");
  }, [needBio]);
  
  return ( 
    <>
    <FormHeader>Biomaterials</FormHeader>
    <InputElementContainerTopDownPositioning 
    label={"Do You Need Biomaterials?"}>
    <InputElementSingleChoiceRadio
      box_selected_callback={(id)=>{; updateActiveBoxId(id)}}
      label_array={[
        "Yes",
        "No",
      ]} />

    { needBio &&
      <>
      <LabelSmall>Which Biomaterials Will Be Needed</LabelSmall>
    <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "biomaterial_which"); } } />
    <LabelSmall>Total Number / Subject Specification</LabelSmall>
    <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "biomaterial_number"); } } />
    <LabelSmall>Volume?</LabelSmall>
    <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "biomaterial_volume"); } } />
    <LabelSmall>Which Marker(s) Should Be Measured?</LabelSmall>
    <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "biomaterial_marker"); } } />
    <LabelSmall>Which Procedure Is Needed To Measure The Marker?</LabelSmall>
    <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "biomaterial_procedure"); } } />
    <BiomaterialMultiProband changeFunction={changeFunction} />
    <LabelSmall>Financing</LabelSmall>
    <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "biomaterial_financial"); } } />
    <LabelSmall>Where Do The Measurements Take Place?</LabelSmall>
    <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "biomaterial_where"); } } />
    <BiomaterialShipping changeFunction={changeFunction} />
      </>}
  </InputElementContainerTopDownPositioning></>);
}

function BiomaterialMultiProband({changeFunction}){
  const [multiProband, setMultiProband] = useState(false);

  return (
    <>
      <LabelSmall>Are There Multiple Determinations Per Propband Necessary?</LabelSmall>
      <InputElementSingleChoiceRadio 
        box_selected_callback={(id)=>{setMultiProband(id === "Yes")}}
        label_array={["Yes", "No"]} />
      {
        multiProband && 
        <>
          <LabelSmall>Reasoning</LabelSmall>
          <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "biomaterial_multiple"); } } />
        </>
      }
    </>
  )
}

function BiomaterialShipping({changeFunction}){
  const [needShipping, setNeedShipping] = useState(false);
  const [returnable, setReturnable] = useState(true);

  return (
    <> 
      <LabelSmall>Will The Biomaterials Be Shipped?</LabelSmall>
      <InputElementSingleChoiceRadio 
        box_selected_callback={(id)=>{setNeedShipping(id === "Yes")}}
        label_array={["Yes", "No"]} />
      { 
        needShipping &&
        <>
          <LabelSmall>To Whom Will They Be Shipped?</LabelSmall>
          <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "biomaterial_shipment"); } } />
          <LabelSmall>Transport Company</LabelSmall>
          <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "biomaterial_transp_company"); } } />
          <LabelSmall>Can The Materials Be Returned After Completion Of Measurements?</LabelSmall>
          <InputElementSingleChoiceRadio 
            box_selected_callback={(id)=>{setReturnable(id === "Yes")}}
            label_array={["Yes", "No"]} />
            {
              !returnable && 
              <>
                <LabelSmall>Reasoning For Materials Not Being Returnable</LabelSmall>
                <InputElementMultilineTextArea onChange={(event) => { changeFunction(event, "biomaterial_return"); } } />

              </>
            }
        </>
      }
    </>
  )
}
