import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
const TooltipTrigger = styled.div ``;
const TooltipContent = styled.div `
  width: max-content;
  max-width: 300px;
  background-color: black;
  color: #fff;
  padding: 0.5rem;
  transition: opacity 0.2s ease-in-out;
  border-radius: 0.2rem;

  position: fixed;
  opacity: 0;
  z-index: 1;
  visibility: hidden;

  &:hover {
    visibility: visible;
    opacity: 0.8;
}
`;
const TooltipWrapper = styled.div `
  & ${TooltipTrigger}:hover + ${TooltipContent} {
    visibility: visible;
    opacity: 0.8;
  }
`;
export function Tooltip({ content, children }) {
    return (_jsxs(TooltipWrapper, { children: [_jsx(TooltipTrigger, { children: children }), _jsx(TooltipContent, { children: content })] }));
}
