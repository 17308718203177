import { useState } from "react";
import { useNavigate } from "react-router";
import { NavigateBack, Submit } from "../buttons/menu_area_buttons";
import { BasicMenuAreaFormStyling, FormInputsWithPositioning } from "./form_layouts";
import { HideExceptAdmin } from "../../authentication/require_auth";
import { useAuth } from "../../authentication/auth_provider";
import { usePageLayout } from "../page_layouts";
import { VariableProposalMapper } from "../variablenmanual/variable_sorter";
import { variablemanual_standalone_association_value } from "../variablenmanual/variablemanual";


//migrated
export function NavForm(props){
    const {role} = useAuth();
    const navigate = useNavigate();
    const {activateOverlayModal} = usePageLayout();

    const accountVerwaltungInputs = 
        <FormInputsWithPositioning>
            <NavigateBack callback={()=>{updateInputs(initialInputs)}}/>
            <Submit button_text="Change Password" callback={()=>{navigate("/account/changePassword")}}/>
            <HideExceptAdmin>
              <Submit button_text="Set Password for user" callback={()=>{navigate("/account/setUserPassword")}}/>
            </HideExceptAdmin>
            <HideExceptAdmin>
              <Submit button_text="Add new user" callback={()=>{navigate("/account/addNewUser")}}/>
            </HideExceptAdmin>
        </FormInputsWithPositioning>

    const anträgeVorhabenInputs = 
        <FormInputsWithPositioning>
            <NavigateBack callback={()=>{updateInputs(initialInputs)}}/>
            <Submit callback={()=>{navigate("/proposals/issueProposal")}} button_text="Issue Proposal"/>
            <Submit callback={()=>{navigate("/proposals/noteIntention")}} button_text="Note Intention"/>
            <Submit callback={()=>{navigate("/proposals/overview")}} button_text="Proposal Overview"/>
            <HideExceptAdmin>
              <Submit callback={()=>{navigate("/proposals/antrag_nachtragen")}} button_text="Add missing Proposal"/>
            </HideExceptAdmin>
        </FormInputsWithPositioning>

    const initialInputs = <FormInputsWithPositioning>
        <Submit callback={()=>{updateInputs(accountVerwaltungInputs); navigate("/account")}} button_text="Account Management"/>
        <Submit callback={()=>{updateInputs(anträgeVorhabenInputs); navigate("/proposals")}} button_text="Proposals"/>
        <Submit button_text="Variable Manual" callback={()=>{updateInputs(variableManualInputs); navigate("/variablemanual")}}/>
        <Submit button_text="Publications" callback={()=>{updateInputs(publikationenInputs); navigate("/publications")}} />
    </FormInputsWithPositioning>

    const publikationenInputs = 
        <FormInputsWithPositioning>
            <NavigateBack callback={()=>{updateInputs(initialInputs)}}/>
        </FormInputsWithPositioning>

    const variableManualInputs = 
      <FormInputsWithPositioning>
        <NavigateBack callback={()=>{updateInputs(initialInputs);}} />
      {/*<Submit button_text="Export Selection"/>*/}
        <Submit button_text="Associate with proposal" 
          callback={()=>{activateOverlayModal(<VariableProposalMapper association={variablemanual_standalone_association_value} />)}}/>
      </FormInputsWithPositioning>

    const [inputs, updateInputs] = useState(initialInputs);

    return (
        <BasicMenuAreaFormStyling>
            {inputs}
        </BasicMenuAreaFormStyling>
    )
}
