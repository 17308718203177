import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { ThemeColor } from "../../Styling/ThemeColors";
import { useProposalSelectionMapping } from "../../Contexts/ProposalSelectionMappingContext";
import { useGraph } from "../../Contexts/GraphContext";
import { isList } from "../../helper_functions";
import { VariablesCSVExporter } from "./VariablesCSVExporter";
import { BorderSolidSlimBlack } from "../../Styling/Borders/BorderSolidSlimBlack";
const DownloadVariablesButtonStyling = styled.div `
  ${BorderSolidSlimBlack};
  padding: 1rem;
  place-self: center;
  align-self: center;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  &:hover {
    background: ${ThemeColor.grey.hover};
    cursor: pointer;
  }
`;
export function DownloadVariablesButton({ association, children }) {
    const { variablesManager } = useProposalSelectionMapping();
    const { getNodeForId } = useGraph();
    const downloadCSV = () => {
        console.log("clicked button");
        console.log(`association is ${association.proposal_id} ${association.kind}`);
        let selected_ids = variablesManager.getSelectionsForProposalAndKind(association.kind, association.proposal_id);
        console.log(`selected_ids are ${selected_ids}`);
        let variable_nodes = selected_ids.map(getNodeForId);
        console.log(`variable_nodes are ${variable_nodes}`);
        if (!isList(variable_nodes) || variable_nodes.length === 0)
            return;
        console.log(`post guard`);
        let exporter = new VariablesCSVExporter(variable_nodes);
        exporter.loopVariablesAddAttributesToTable(exporter.variables_attributes_by_name);
        const table_content = exporter.exportTableToCSV();
        let export_blob = new Blob([table_content], { type: 'text/csv;charset=utf-8' });
        let url = URL.createObjectURL(export_blob);
        let downloadElement = document.createElement('a');
        downloadElement.href = url;
        downloadElement.setAttribute('download', 'variable_selection.csv');
        downloadElement.click();
    };
    return _jsx(DownloadVariablesButtonStyling, { onClick: () => { console.log("click"); downloadCSV(); }, children: children });
}
