import styled from "styled-components";
export const SingleIconWidgetButton = styled.button `
    width: 100%;
    height: 100%;
    font-size: 24;
    border-radius: 30px;
    background-color: white;
    border-style: solid;
    border-color: gray;
`;
