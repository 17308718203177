import styled from "styled-components";
export const BigButton_base = styled.button `
    font-size: 14px;
    margin-top: 30px;
    padding: 16px 20px;
    border-radius: 26px;
    border: 1px solid #d4d3e8;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    transition: 0.2s;
    position: relative;
    background: #fff;
    color: #4c489d;
    box-shadow: 4px 2px 1px #e8b2b2;

    &:active,
    &:focus,
    &:hover {
      box-shadow: inset 0px 0px 1px #2a1ea6;
    }
`;
