import styled from "styled-components";
import { BorderSolidRed, BorderSolidSlimBlack } from "../../../Styling/PackageExports";
export const InputItem = styled.div `
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;

    && > * {
        ${props => (props.$invalid && BorderSolidRed) || BorderSolidSlimBlack}
        padding: 5px;
        margin: 4px;
        min-height: 22px;
    }
`;
