import styled, { css } from "styled-components";
import { ThemeColor } from "../themes and styles/colors";

export const ThemeBorder = {
  solidLightBlue: 1
}

export const BorderSolidLightBlue = css`
  border: 3px solid ${ThemeColor.lightblue};
`

export const BorderSolidRed = css`
  border: 3px solid ${ThemeColor.lightred};
`

export const BorderSolidSlimBlack = css`
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
`

export const PaddedBorderSlimBlack = styled.div`
  ${BorderSolidSlimBlack};
  padding: 1rem;
`