import { useProposalSelectionMapping } from "../../components/variablenmanual/contexts";
import { arrayToCsv, structuredClone, transpose } from "typescript_helpers";
import { Node } from "typescript_helpers";


export class VariablesCSVExporter{
  constructor(variable_nodes){
    this.variable_nodes = structuredClone(variable_nodes);
    this.variables_attributes_by_name = this.orderAttributesByName(this.variable_nodes);
    this.CSVHeaders = this.getCSVHeaders(this.variable_nodes);
    this.table = this.buildTableStructure(this.CSVHeaders);
  }

  buildTableStructure(CSVHeaders){
    let table = {};
    for (const header of CSVHeaders){
      table[header] = [];
    }
    return table;
  }

  orderAttributesByName(variable_nodes){
    let result = [];
    for (const node of variable_nodes){
      result.push({id: node.id, attributes: Node.getAttributesByName(node)});
    }
    return result;
  }

  exportTableToCSV(){
    let columns = [];
    for (const [header, column] of Object.entries(this.table)){
      columns.push([header].concat(column));
    }
    return arrayToCsv(transpose(columns));
  }

  downloadCSV(){
    
  }

  loopVariablesAddAttributesToTable(variables_attributes_by_name){
    for (const attribute_by_name of variables_attributes_by_name){
      this.variableAttributesByNameToTableEntry(attribute_by_name);
    }
  }

  variableAttributesByNameToTableEntry(variable_attributes_by_name){
    const ArgError = new Error(`variable_attributes_by_name does not match expected format. Expecting {id: 'node_id', attributes: {'detailName': {attributeName: 'detailName', attributeContent: 'some content', visibility: 'one of visible|internal|hidden'}}}
actual value is ${JSON.stringify(variable_attributes_by_name)}`);
    if (
      !variable_attributes_by_name.hasOwnProperty('id') ||
      !variable_attributes_by_name.hasOwnProperty('attributes')){
      throw ArgError;
    }
    //throw new Error(JSON.stringify(this.table));
    const attributes = variable_attributes_by_name.attributes;

    for (const header of this.CSVHeaders){

      if (!attributes.hasOwnProperty(header)){
        this.table[header].push("");
        continue;
      }
      if (!attributes[header].hasOwnProperty('visibility') || 
          !attributes[header].hasOwnProperty('attributeContent')) throw ArgError;
      if (attributes[header].visibility !== 'visible') {
        this.table[header].push("");
      }
      else {
        this.table[header].push(attributes[header].attributeContent)
      }
    }
  }

  getCSVHeaders(variable_nodes){
    let headers = {};
    for (const variable of variable_nodes){
      for (const detail of variable.details){
        const detail_name = detail['attributeName'];
        headers[detail_name] = true;
      }
    }
    return Object.keys(headers);
  }
}

