import { useEffect, useState } from "react"
import styled from "styled-components"
import { isFunction, isOneOf } from "typescript_helpers"
import { BorderSolidSlimBlack } from "../themes and styles/borders"


const OpenButton = styled.button`
  cursor: pointer;
  padding: 1rem;
  ${BorderSolidSlimBlack};
`

const Menu = styled.ul`
  position: absolute;

  list-style-type: none;
  margin: 5px 0;
  padding: 0;

  z-index: 1;
  ${BorderSolidSlimBlack};
  width: 150px;

  & > li {
    margin: 0;
    background-color: white;
  }

  & > li:hover {
    background-color: lightgray;
  }

  & > li > button {
    width: 100%;
    height: 100%;
    text-align: left;
  
    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }
`

export function Dropdown({defaultSelection, setSelectionCallback, MenuOptions}){
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  const [selected, setSelected] = useState("");

  useEffect(()=>{
    if(! isOneOf(defaultSelection, [null, undefined])){
      setSelected(defaultSelection);
    }
  }, []);

  const handleOptionClick = (option) => {
    setSelected(option);
    setOpen(false);
    isFunction(setSelectionCallback) &&
    setSelectionCallback(option);
  }

  return (
    <div style={{position: "relative"}}>
      <OpenButton onClick={handleOpen}>{selected}</OpenButton>
      {open ? (
        <Menu>
          {! isOneOf(MenuOptions, [null,  undefined]) &&
            MenuOptions.map((option, index)=>{
            return <li key={index}>
              <button onClick={()=>{handleOptionClick(option)}}>{option}</button>
            </li>
          })}
        </Menu>
      ) : null}
    </div>
  )
}
