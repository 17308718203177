import styled from "styled-components";
import { ThemeColor } from "../themes and styles/colors";

const RedBarBase = styled.span`
  border-radius: 10px;
  border-radius: 10px;
  background-color: ${ThemeColor.red};
  border-style: solid;
  border-color: ${ThemeColor.red};
  display: inline-block;
  justify-self: center;

`

export const RedDividerBar = styled(RedBarBase)`
  width: ${props => props.width || "100%"};
  height: 2px;

`

export const RedPartitionBar = styled(RedBarBase)`
  height: ${props => props.height || "100%"};
  width: 2px;
`
