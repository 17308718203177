import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
const FormShape1 = styled.span `
    height: 220px;
    width: 220px;
    background: #6c63ac;
    top: -148px;
    right: 4px;
    border-radius: 32px;
    transform: rotate(45deg);
`;
const FormShape2 = styled.span `
    height: 540px;
    width: 190px;
    background: linear-gradient(270deg, #5d54a4, #6a679e);
    top: 0px;
    right: 0px;
    border-radius: 32px;
    transform: rotate(45deg);
`;
const FormShape3 = styled.span `
    height: 400px;
    width: 202px;
    background: #7e7bb9;
    top: 362px;
    right: -24px;
    border-radius: 60px;
    transform: rotate(45deg);
`;
const FormShape4 = styled.span `
    height: 520px;
    width: 520px;
    background: #fff;
    top: -26px;
    right: 141px;
    border-radius: 72px;
    transform: rotate(45deg);
`;
const BackgroundStylingContainer = styled.div `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    z-index: 0;
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);

    & > span{
        position: absolute;
    } 
`;
export function MenuAreaFormBackground() {
    return (_jsxs(BackgroundStylingContainer, { children: [_jsx(FormShape1, {}), _jsx(FormShape2, {}), _jsx(FormShape3, {}), _jsx(FormShape4, {})] }));
}
