import { Collection, CombinedText, Text } from "./structuring_elements";

export const collection_introduction = new Collection(
  'Introduction',
  [
    new Text('Project Title', 'intro_project_title'),
    new CombinedText(
      'Applicant / Coordination Investigator',
      ['intro_applicant_firstname', 'intro_applicant_lastname']
    ),
    new Text('Background / Rational / Prior Data', 'intro_background'),
    new Text('Objective(s) / Hypothesis', 'intro_objectives'),
    new Text('Known Content Overlap With Other Proposals', 'intro_overlap')
  ]
)

