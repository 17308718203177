import { AutoHeightRowGrid } from "../divider and frames/layouts";
import styled from "styled-components";
import { MenuIcon, QuestionIcon, RoundIconWrapper } from "../icons/font_awesome_icons";
import { Tooltip } from "./tooltip";

const OptionContainerStyling = styled.div`
  margin: 0.5em;
`;

function OptionContainer({ children }) {
  return (
    <OptionContainerStyling>
      <AutoHeightRowGrid
        template_rows={"1fr"}
        rowGap={"1rem"}
        column_gap={"1rem"}
        column_template={"auto"}
        width={"max-content"}
      >
        {children}
      </AutoHeightRowGrid>
    </OptionContainerStyling>

  )
}

function InfoOption({tooltip_text}) {
  return (
      <Tooltip content={tooltip_text}>
    <RoundIconWrapper>
        <QuestionIcon />
    </RoundIconWrapper>
      </Tooltip>
  )
}

function MenuOption(props) {
  return (
    <RoundIconWrapper>
      <MenuIcon />
    </RoundIconWrapper>
  )
}

export function InfoMenu({tooltip_text}) {

  return (
    <OptionContainer>
      <InfoOption tooltip_text={tooltip_text}/>
      <MenuOption/>
    </OptionContainer>
  )
}