import { AuthProvider } from "./authentication/auth_provider";
import { SelectionProvider } from "./components/variablenmanual/variable_state_manager";
import { GraphProvider, CurrentVariableProvider, CurrentVMLinksProvider, VariableHistoryProvider, SelectionMappingProvider, CurrentVariablesProvider, VMHistoriesProvider } from "./components/variablenmanual/contexts";

export function ContextProviders({ children }) {
  return (
    <AuthProvider>
      <GraphProvider>
        <CurrentVariableProvider>
          <CurrentVariablesProvider>
            <CurrentVMLinksProvider>
              <SelectionProvider>
                <VMHistoriesProvider>
                  <VariableHistoryProvider>
                    <SelectionMappingProvider>
                      {children}
                    </SelectionMappingProvider>
                  </VariableHistoryProvider>
                </VMHistoriesProvider>
              </SelectionProvider>
            </CurrentVMLinksProvider>
          </CurrentVariablesProvider>
        </CurrentVariableProvider>
      </GraphProvider>
    </AuthProvider>
  )
}
