import { deepEqual, isNonNullObject, isString, objectKeysValid, structuredClone } from "./helper_functions";
export function isLinkLike(l) {
    return (isNonNullObject(l) &&
        objectKeysValid(l, ["linkId", "source", "target"]));
}
export class Link {
    linkId;
    source;
    target;
    constructor(id, source, target) {
        this.linkId = id;
        this.source = source;
        this.target = target;
    }
    static oneOfLinks(link, links) {
        for (const listed_link of links) {
            if (Link.linksEqual(link, listed_link)) {
                return true;
            }
        }
        return false;
    }
    static isLinkLike(link) {
        return isLinkLike(link);
    }
    isLinked(pairing) {
        if (isString(pairing)) {
            return (this.source === pairing ||
                this.target === pairing);
        }
        else {
            for (const link_partner of pairing) {
                if (this.isLinked(link_partner))
                    return true;
            }
            return false;
        }
    }
    getId() {
        return this.linkId;
    }
    isLinkedTo(id) {
        return this.target === id;
    }
    isLinkedFrom(id) {
        return this.source === id;
    }
    hasPairIn(sources_targets) {
        return (sources_targets.includes(this.source) &&
            sources_targets.includes(this.target));
    }
    getLinkCopy() {
        return new Link(this.linkId, this.source, this.target);
    }
    getLinkPartner(source_or_target) {
        if (this.source === source_or_target)
            return this.target;
        if (this.target === source_or_target)
            return this.source;
        throw new Error(`For instanceof Link link - link.getLinkPartner(source_or_target) -> source_or_target is expected to be link.source or link.target
  \t link.source = ${this.source}
  \t lind.target = ${this.target}`);
    }
    static getLinkData(link) {
        return structuredClone({ source: link.source, target: link.target });
    }
    static linksEqual(link1, link2) {
        return deepEqual(Link.getLinkData(link1), Link.getLinkData(link2));
    }
    static getLinksById(links) {
        return Object.fromEntries(links.map((link) => {
            let lCopy = link.getLinkCopy();
            return [lCopy.getId(), lCopy];
        }));
    }
}
