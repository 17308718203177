import { isList, isNonNullObject, isOneOf, isString, objectHasKeys } from "./helper_functions";
export const eval_true_values = ["yes", "1", "true"];
export function isVisibility(elem) {
    return isOneOf(elem, ["intern", "internal", "hidden", "visible", "public"]);
}
export const variablemanual_standalone_accociation_value = {
    proposal_id: "none",
    kind: "variablemanual_standalone_selection"
};
export function isDetail(elem) {
    return (isNonNullObject(elem) &&
        objectHasKeys(elem, ["attributeName", "attributeContent"]) &&
        isString(elem.attributeName) &&
        isString(elem.attributeContent));
}
export function isDetails(elem) {
    if (!isList(elem))
        return false;
    for (const entry of elem) {
        if (!isDetail(entry))
            return false;
    }
    return true;
}
//export type NodeLike = InstanceType<typeof Node> | NodeShape;
