import logo from './logo.svg';
import './App.css';

import { BrowserRouter } from 'react-router-dom';
import LandingPage from './components/landing_page.js';
import { AuthProvider } from './authentication/auth_provider';
import { Test } from './components/Testing';
import { ContextProviders } from './context_providers';
import { TSContextProviders } from 'typescript_helpers';

export const TESTMODE = () => {
  //return true;
  return false;
} 
function App() {
  if (TESTMODE()){
    return (
      <Test></Test>
    )
  }
  return (
    <BrowserRouter>
      <ContextProviders>
          <LandingPage/>
      </ContextProviders>
    </BrowserRouter>
  );
}

export default App;
