import { isNonNullObject, isOneOf } from "typescript_helpers";
import { request_success, get_request, fetch_json_endpoint } from "./RequestHelpers";
import { test_jwt, test_requestor_mail } from "./test_config";

const CREATE_PROPOSAL_URL = "https://api.hchs.hamburg/api_endpoints/create_proposal.php";
const UPDATE_PROPOSAL_URL = "https://api.hchs.hamburg/api_endpoints/update_proposal.php";
const GET_USER_PROPOSAL_URL = "https://api.hchs.hamburg/api_endpoints/get_proposals_of_user.php";
const GET_PROPOSAL_BY_ID_URL = "https://api.hchs.hamburg/api_endpoints/get_proposal_for_id.php";
const GET_ALL_PROPOSALS_URL = "https://api.hchs.hamburg/api_endpoints/get_all_proposals.php";
const UPDATE_STATUS_URL = "https://api.hchs.hamburg/api_endpoints/set_proposal_status.php";
const PROPOSAL_NACHTRAGEN_URL = "https://api.hchs.hamburg/api_endpoints/proposal_nachtragen.php";

export class Proposal {
  //tested
  constructor(){
    this.db_response = null;
    
    this.proposal_data = {
      id: null, 
      USER: null, 
      date: null, 
      Variables: null, 
      intro_applicant_firstname: null, 
      intro_applicant_lastname: null, 
      intro_project_title: null, 
      intro_background: null, 
      intro_objectives: null, 
      intro_overlap: null, 
      intro_novelty: null, 
      methods_outcome: null, 
      methods_description: null, 
      methods_confounder: null, 
      methods_design: null, 
      methods_design_prosp: null, 
      methods_design_other: null, 
      methods_sample_size: null, 
      methods_sample_size_exception: null, 
      methods_power_calc: null, 
      methods_setting_baseline: null, 
      methods_setting_yearly: null, 
      methods_setting_yearly_year: null, 
      methods_setting_mri: null, 
      methods_setting_mri_cardio: null, 
      methods_setting_mri_neuro: null, 
      methods_setting_other: null, 
      methods_setting_other_statement: null, 
      methods_timeline: null, 
      methods_bias_limitations: null, 
      analysis_exclusion: null, 
      analysis_inclusion: null, 
      analysis_subgroups: null, 
      analysis_missing_variables: null, 
      analysis_statistical_methods: null, 
      analysis_exam_methods: null, 
      analysis_missing_data: null, 
      analysis_loss_fu: null, 
      analysis_sensitivity_analyses: null, 
      analysis_presentation_type: null, 
      need_bio: null, 
      biomaterial_which: null, 
      biomaterial_number: null, 
      biomaterial_volume: null, 
      biomaterial_marker: null, 
      biomaterial_procedure: null, 
      biomaterial_multiple: null, 
      biomaterial_where: null, 
      biomaterial_financial: null, 
      biomaterial_shipment: null, 
      biomaterial_transp_company: null, 
      biomaterial_return: null, 
      biomaterial_leftover: null, 
      other_ip_rights: null, 
      other_partners: null, 
      other_financial: null, 
      other_manuscript: null, 
      final: null,
      status: null,
    }

    this.rendering_structure = [
      {
        type: 'collection',
        name: 'Introduction',
        sections: [
          {
            type: 'text',
            name: 'Project Title',
            content_key: 'intro_project_title'
          },
          {
            type: 'combined text',
            name: 'Applicant / Coordination Investigator',
            content_keys: ['intro_applicant_firstname', 'intro_applicant_lastname'] 
          },
          {
            type: 'text',
            name: 'Background / Rational / Prior Data',
            content_key: 'intro_background'
          },
          {
            type: 'text',
            name: 'Objective(s) / Hypothesis',
            content_key: 'intro_objectives'
          },
          {
            type: 'text',
            name: 'Known Content Overlap With Other Proposals',
            content_key: 'intro_overlap'
          }
        ]
      },
      {
        type: 'collection',
        name: 'Methodology',
        sections: [
          {
            type: 'conditional selection',
            name: 'Setting',
            options: [
              {
                type: 'multi choice',
                name: 'Baseline',
                content_key: 'methods_setting_baseline',
                conditional_section: false
              },
              {
                type: 'multi choice',
                name: 'Yearly Questionaire',
                content_key: 'methods_setting_yearly',
                conditional_section: {
                  type: 'optional text',
                  content_key: 'methods_setting_yearly_year',
                  hint_text: 'Indicate Year'
                }
              },
              {
                type: 'multi choice',
                name: 'MRI',
                content_key: 'methods_setting_mri',
                conditional_section: {
                  type: 'conditional_section',
                  name: 'Select kind of MRI examination',
                  options: [
                    {
                      type: 'multi choice',
                      name: 'cardio',
                      content_key: 'methods_setting_mri_cardio',
                      conditional_section: false
                    },
                    {
                      type: 'multi choice',
                      name: 'neuro',
                      content_key: 'methods_setting_mri_neuro',
                      conditional_section: false
                    },
                  ]
                }
              },
              {
                type: 'multi choice',
                name: 'Others',
                content_key: 'methods_setting_other',
                conditional_section: {
                  type: 'optional text',
                  content_key: 'methods_setting_other_statement',
                  hint_text: 'Statement'
                }
              }
            ]
          },
          {
            type: 'conditional selection',
            name: 'HCHS Cohort',
            options: [
              {
                type: 'single choice',
                name: '10.000',
                content_key: 'methods_sample_size',
                conditional_section: false
              },
              {
                type: 'single choice',
                name: '17.000', 
                content_key: 'methods_sample_size',
                conditional_section: false
              },
              {
                type: 'single choice',
                name: 'post-COVID',
                content_key: 'methods_sample_size',
                conditional_section: false
              },
              {
                type: 'single choice',
                name: 'Exceptions',
                content_key: 'methods_sample_size_exception',
                conditional_section: {
                  type: 'optional text',
                  content_key: 'methods_sample_size_exception',
                  hint_text: 'Indicate Further Information'
                }
              }
            ]
          },
          {
            type: 'text',
            name: 'Power Calculation',
            content_key: 'methods_power_calc'
          },
          {
            type: 'text',
            name: 'Bias and Limitation',
            content_key: 'methods_bias_limitations'
          },
          {
            type: 'text',
            name: 'Estimated Timeline',
            content_key: 'methods_timeline'
          }
        ]
      },
      {
        type: 'collection',
        name: 'Plan of Analysis',
        sections: [
          {
            type: 'subcollection',
            name: 'Definition Of Cohort',
            sections: [
              {
                type: 'text',
                name: 'Exclusion',
                content_key: 'analysis_exclusion'
              },
              {
                type: 'text',
                name: 'Inclusion',
                content_key: 'analysis_inclusion',
              },
              {
                type: 'text',
                name: 'Define Subgroups (if applicable)',
                content_key: 'analysis_subgroups'
              }
            ]
          },
          {
            type: 'subcollection',
            name: 'Data Needed (Variables)',
            sections: [
              {
                type: 'text',
                name: 'Description Of Outcome / Dependent Variable(s)',
                content_key: 'methods_outcome'
              },
              {
                type: 'text',
                name: 'Description Of Exposure / Independent Variable(s)',
                content_key: 'methods_description'
              },
              {
                type: 'text',
                name: 'Description Of Confounder / Covariates',
                content_key: 'methods_confounder'
              }
            ]
          },
          {
            type: 'text',
            name: 'Handling Of Missing Variables',
            content_key: 'analysis_missing_variables'
          },
          {
            type: 'subcollection',
            name: 'What Kind Of Analysis Is Planed',
            sections: [
              {
                type: 'text',
                name: 'Describe All Statistical Methods, Including Those Used To Control For Confounding',
                content_key: 'analysis_statistical_methods'
              },
              {
                type: 'text',
                name: 'Describe Any Methods Used To Examine Subgroups And Interactions',
                content_key: 'analysis_subgroups'
              },
              {
                type: 'text',
                name: 'Explain How Missing Data Were Addressed',
                content_key: 'analysis_missing_data'
              },
              {
                type: 'text',
                name: 'If Applicable, Explain How Loss To Follow-Up Was Addressed',
                content_key: 'analysis_loss_fu'
              },
              {
                type: 'text',
                name: 'Describe Any Sensitivity Analyses',
                content_key: 'analysis_sensitivity_analyses'
              }
            ]
          },
          {
            type: 'text',
            name: 'Planned Type of Publication',
            content_key: 'analysis_presentation_type'
          }
        ]
      },
      {
        type: 'collection',
        name: 'Biomaterials',
        sections: [
          {
            type: 'conditional selection',
            name: 'Do You Need Biomaterials?',
            options: [
              {
                type: 'single choice',
                name: 'Yes',
                content_key: 'need_bio',
                conditional_section: {
                  type: 'grouping',
                  sections: [
                    {
                      type: 'text',
                      name: 'Which Biomaterials Will Be Needed?',
                      content_key: 'biomaterial_which'
                    },
                    {
                      type: 'text',
                      name: 'Total Number / Subject Specification',
                      content_key: 'biomaterial_number'
                    },
                    {
                      type: 'text',
                      name: 'Volume?',
                      content_key: 'biomaterial_volume'
                    },
                    {
                      type: 'text',
                      name: 'Which Marker(s) Should Be Measured?',
                      content_key: 'biomaterial_marker'
                    },
                    {
                      type: 'text',
                      name: 'Which Procedure Is Needed To Measure The Marker?',
                      content_key: 'biomaterial_procedure'
                    },
                    {
                      type: 'conditional selection', 
                      name: 'Are There Multiple Determinations Per Propband Necessary?',
                      options: [
                        {
                          type: 'single choice',
                          name: 'Yes',
                          content_key: 'biomaterial_multiple',
                          conditional_section: {
                            type: 'text',
                            name: 'Reasoning',
                            content_key: null,
                          }
                        },
                        {
                          type: 'single choice',
                          name: 'No',
                          content_key: 'biomaterial_multiple',
                          conditional_section: false
                        }
                      ]
                    },
                    {
                      type: 'text',
                      name: 'Financing',
                      content_key: 'biomaterial_financial'
                    },
                    {
                      type: 'text',
                      name: 'Where Do The Measurements Take Place?',
                      content_key: 'biomaterial_where'
                    },
                    {
                      type: 'conditional selection',
                      name: 'Will The Biomaterials Be Shipped?',
                      options: [
                        {
                          type: 'single choice',
                          name: 'Yes',
                          content_key: 'biomaterial_shipment',
                          conditional_section: {
                            type: 'grouping',
                            sections: [
                              {
                                type: 'text',
                                name: 'To Whom Will They Be Shipped?',
                                content_key: null
                              },
                              {
                                type: 'text',
                                name: 'Transport Company',
                                content_key: 'biomaterial_transp_company'
                              },
                              {
                                type: 'conditional selection',
                                name: 'Can The Materials Be Returned After Completion Of Measurements?',
                                options: [
                                  {
                                    type: 'single choice',
                                    name: 'Yes',
                                    content_key: 'biomaterial_return',
                                    conditional_section: false
                                  },
                                  {
                                    type: 'single choice',
                                    name: 'No',
                                    content_key: 'biomaterial_return',
                                    conditional_section: {
                                      type: 'text',
                                      name: 'Reasoning For Materials Not Being Returnable',
                                      content_key: null
                                    }
                                  }
                                ]
                              }
                            ]
                          }
                        },
                        {
                          type: 'single choice',
                          name: 'No',
                          content_key: 'biomaterial_shipment',
                          conditional_section: false
                        }
                      ]
                    }
                  ]
                }
              },
              {
                type: 'single choice',
                name: 'No',
                content_key: 'need_bio',
                conditional_section: false
              }
            ]
          }
        ]
      }
    ]
  }

  //tested
  //migrated
  validate_id() {
    if (this.proposal_data.id === null) {
      throw new Error("Proposal id isn't set. Request not possible");
    }
  }

  //tested indirectly
  //migrated
  onMissingAttribute(attribute_id){
    if(! this.proposal_data.hasOwnProperty(attribute_id)) throw new Error(`proposal has no property ${attribute_id}`);
  }

  //tested
  //migrated
  getProposalAttribute(attribute_id){
    this.onMissingAttribute(attribute_id);
    return this.proposal_data[attribute_id];
  }

  //tested
  //migrated
  setProposalAttribute(attribute_id, attribute_content){
    this.onMissingAttribute(attribute_id);
    this.proposal_data[attribute_id] = attribute_content;
  }

  //tested
  //migrated
  getResponseStatus(){
    if (isNonNullObject(this.db_response) 
      && this.db_response.status !== undefined
      && this.db_response.status !== null) return this.db_response.status;
    return "no response status available";
  }

  //tested
  //migrated
  getResponseMessage(){
    if (isNonNullObject(this.db_response) 
      && this.db_response.message !== undefined 
      && this.db_response.message !== null) return this.db_response.message;
    return "no response message available";
  }

  //migrated
  isSampleSizeNonStandard(){
    return ! isOneOf(this.getProposalAttribute('methods_sample_size'), ['10000', '17000', '17.000', '10.000', "", null, "post-COVID", "post covid", "post-Covid"]);
  }

  //migrated
  hasProspectiveFollowUp(){
    return isOneOf(this.getProposalAttribute('methods_design'), 
      [
        'Prospective with Follow-up', 
        'prospective with Follow-up', 
        'Prospective with FollowUp', 
        'Prospective Follow-up', 
        'prospective Follow-up', 
        'Prospective FollowUp', 
        'Prospective with Follow-Up', 
        'prospective with Follow-Up', 
        'Prospective with Followup', 
        'Prospective Follow-Up', 
        'prospective Follow-Up', 
        'Prospective Followup', ]);
  }

  //migrated
  hasDesignOther(){
    return isOneOf(this.getProposalAttribute('methods_design'),
      [
        'Other',
        'other',
        'Other (please elaborate)',
        'other (please elaborate)',
      ]
    )
  }

  //migrated
  hasSettingBaseline(){
    return isOneOf(this.getProposalAttribute('methods_setting_baseline'),
      [
        'Baseline',
        'baseline',
        'yes',
        '1',
        'true'
      ]
    )
  }
  
  //migrated
  hasSettingYearly(){
    return isOneOf(this.getProposalAttribute('methods_setting_yearly'),
      [
        'yearly',
        'yearly questionaire',
        'Yearly',
        'Yearly questionaire',
        'yearly Questionaire',
        'Yearly Questionaire',
        'yes',
        '1',
        'true'
      ]
    )
  }

  //migrated
  hasSettingMRI(){
    return isOneOf(this.getProposalAttribute('methods_setting_mri'),
      [
        'MRI',
        'mri',
        'MRT',
        'mrt',
        'yes',
        '1',
        'true'
      ]
    )
  }

  //migrated
  hasSettingMRIcardio(){
    return isOneOf(this.getProposalAttribute('methods_setting_mri_cardio'),
      [
        'cardio',
        'Cardio',
        'yes',
        '1',
        'true'
      ]
    )
  }

  //migrated
  hasSettingMRIneuro(){
    return isOneOf(this.getProposalAttribute('methods_setting_mri_neuro'),
      [
        'neuro',
        'Neuro',
        'yes',
        '1',
        'true'
      ]
    )
  }

  //migrated
  hasSettingOther(){
    return isOneOf(this.getProposalAttribute('methods_setting_other'),
      [
        'other',
        'Other',
        'yes',
        '1',
        'true'
      ]
    )
  }

  //migrated
  areBiomaterialsNeeded(){
    return isOneOf(this.getProposalAttribute('need_bio'), [
      'yes',
      '1',
      'true'
    ])
  }

  //tested positive cases
  //todo testing negative cases
  //migrated
  async db_create_proposal(requestor_mail, auth_token){
    this.setProposalAttribute('status', 'proposal issued');
    const request_body = {
      mail: requestor_mail,
      jwt: auth_token,
      proposal_data: this.proposal_data
    };
    const response = await fetch_json_endpoint(CREATE_PROPOSAL_URL, request_body);
    this.db_response = response;
    try{
      Proposal.onErrorResponse(response);
    } catch(error){
      throw error;
    }
    this.proposal_data.id = response.request_details.proposal_id;
    return this;
    
  }

  //migrated
  async db_proposal_nachtragen(requestor_mail, auth_token){
    requestor_mail = test_requestor_mail;
    auth_token = test_jwt;

    const request_body = {
      mail: requestor_mail,
      jwt: auth_token,
      proposal_data: this.proposal_data
    };
    const response = await fetch_json_endpoint(PROPOSAL_NACHTRAGEN_URL, request_body);
    this.db_response = response;
    try{
      Proposal.onErrorResponse(response);
    } catch(error){
      throw error;
    }
    this.proposal_data.id = response.request_details.proposal_id;
    return this;
  }

  //migrated
  async db_set_status(requestor_mail, auth_token){
    requestor_mail = test_requestor_mail;
    auth_token = test_jwt;
    this.validate_id();
    const request_body = {
      mail: requestor_mail,
      jwt: auth_token,
      proposal_id: this.getProposalAttribute('id'),
      proposal_status: this.getProposalAttribute('status'),
    };
    const response = await fetch_json_endpoint(UPDATE_STATUS_URL, request_body);
    this.db_response = response;
    try{
      Proposal.onErrorResponse(response);
    } catch(error){
      throw error;
    }
    return this;
  }

  //tested positive cases
  //todo testing negative cases  
  //migrated
  async db_update_proposal(requestor_mail, auth_token){
    this.validate_id();
    const request_body = {
      mail: requestor_mail,
      jwt: auth_token,
      proposal_data: this.proposal_data
    };
    const response = await fetch_json_endpoint(UPDATE_PROPOSAL_URL, request_body);
    this.db_response = response;
    try{
      Proposal.onErrorResponse(response);
    } catch(error){
      throw error;
    }
    return this;
  }

  //tested positive cases
  //todo testing negative cases
  //migrated
  async db_get_proposal_data(requestor_mail, auth_token){
    requestor_mail = test_requestor_mail;
    auth_token = test_jwt;
    this.validate_id();
    const request_body = {
      mail: requestor_mail,
      id: this.proposal_data.id,
      jwt: auth_token
    };
    const response = await fetch_json_endpoint(GET_PROPOSAL_BY_ID_URL, request_body);
    try{
      Proposal.onErrorResponse(response);
    } catch(error){
      throw error;
    }
    for (const [
      attribute_id, 
      attribute_content ] of Object.entries(response.request_details.proposal_data)){
        this.setProposalAttribute(attribute_id, attribute_content);
    }
    return this;
  }

  //tested
  static onErrorResponse(response) {
    if (!request_success(response))
      Proposal.throwRequestError(response);
  }

  //tested by proxy
  static throwRequestError(response) {
    throw new Error(`some error occured while processing request. Response is ${JSON.stringify(response)}`);
  }

  //migrated
  static async db_get_proposals_for_user(user_mail, auth_token){
    const request_body = {
      mail: user_mail,
      jwt: auth_token,
    };
    const response = await fetch_json_endpoint(GET_USER_PROPOSAL_URL, request_body);
    try{
      Proposal.onErrorResponse(response);
    } catch(error){
      throw error;
    }
    const proposals = response.request_details.data.map(
      (proposal_data) => {
        let proposal = new Proposal();
        proposal.setProposalData(proposal_data);
        return proposal;
      }
    );
    return proposals;
  }

  //migrated
  static async db_get_all_proposals(user_mail, auth_token){
    user_mail = test_requestor_mail;
    auth_token = test_jwt;
    const request_body = {
      mail: user_mail,
      jwt: auth_token,
    };
    const response = await fetch_json_endpoint(GET_ALL_PROPOSALS_URL, request_body);
    try{
      Proposal.onErrorResponse(response);
    } catch(error){
      throw error;
    }
    const proposals = response.request_details.proposals.map(
      (proposal_data) => {
        let proposal = new Proposal();
        proposal.setProposalData(proposal_data);
        return proposal;
      }
    );
    return proposals;
  }

  //tested
  //migrated
  setProposalData(proposal_data_collection){
    for (const [
      attribute_id, 
      attribute_content ] of Object.entries(proposal_data_collection)){
        this.setProposalAttribute(attribute_id, attribute_content);
    }
  }
}
