import { useState, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

const LOGIN_VALIDATION_URL = "https://api.hchs.hamburg/api_endpoints/get_validate_login.php"

//Erzeugt Context für Funktionen, die mit Authentifizierung zu tun haben
const AuthContext = createContext(null);

//Wrapper Funktion, um den Authentication Context zu laden
export const useAuth = () => {
    return useContext(AuthContext);
}

export const AuthProvider = ({children}) => {
    const [token, setToken] = useState(null);
    const [requestFeedbackMessage, setMessage] = useState(null);
    const [authStatus, setAuthStatus] = useState(null);
    const [userRole, setUserRole] = useState("");
    const [user_mail, setUserMail] = useState("");
    const [logged_in, set_logged_in] = useState(false);
    const navigate = useNavigate();


    const onLoginFail = () => {
        setMessage("Login Failed. Username or password are incorrect.");
        setAuthStatus('login_fail');
        setToken(null);
        navigate("/login");
    }

    const onLoginError = () => {
        setMessage("Login Failed due to some error. Please wait and try again.");
        setAuthStatus('login_error');
        setToken(null);
        navigate("/login");
    }

    const onLoginSuccess = (response) => {
        setMessage('Login success');
        setToken(response.token);
        setUserRole(response.role);
        setUserMail(response.mail);
        setAuthStatus('login_success');
        set_logged_in(true);
        navigate("/start");
    }

    const mockLogin = (userDataJSON) => {
        onLoginSuccess({token: "mock login token"});
    }


  function handleLoginValidationResponse(resp) {
    switch (resp.status){
      case 'login_fail':
        onLoginFail();
        break;
      case 'server_error':
        onLoginError();
        break;
      case 'login_success':
        onLoginSuccess(resp);
        break;
    }
  }

    async function get_login_validation_from_server(userDataJSON, callback){
      const request = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: `mail=${userDataJSON.mail}&password=${userDataJSON.password}`
      };
      
      fetch(LOGIN_VALIDATION_URL, request)
        .then((response) => { return response.json(); })
        .then((result) => { callback(result); })
        .catch((error) => {
          const result = {
            'status': 'server_error',
            'message': error
          };
          callback(result);
        })
    }

    const onLogin = (userDataJSON) => {
      setUserMail(userDataJSON.mail);
      get_login_validation_from_server(userDataJSON, (response)=>{handleLoginValidationResponse(response)});
    }


    const value = {
        token: token,
        mail: user_mail,
        role: userRole,
        logged_in: logged_in,
        onLogin: onLogin,
        onLogout: null,
        onRequest: null,
        isAdmin: (userRole === 'admin'),
        authStatus: authStatus
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}