import { isNonNullObject, isOneOf, isString, objectHasKeys, stringify } from "./helper_functions";
export function isResponseStatus(status) {
    return (isString(status) &&
        isOneOf(status, ['server_error', 'request_success']));
}
export function isResponse(resp) {
    return (isNonNullObject(resp) &&
        objectHasKeys(resp, ["status", "message"]));
}
export function get_request(body) {
    if (isOneOf(body.jwt, [null, undefined]))
        throw new Error("no auth token provided");
    const request = {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=UTF-8"
        },
        body: stringify(body)
    };
    return request;
}
export async function fetch_json_endpoint(endpoint_url, request_body) {
    try {
        const result = await fetch(endpoint_url, get_request(request_body));
        const response = await result.json();
        return response;
    }
    catch (error) {
        const response = {
            'status': 'server_error',
            'message': error
        };
        return response;
    }
}
export function request_success(response) {
    return response !== null && response.status === 'request_success';
}
export function onErrorResponse(response) {
    if (!request_success(response)) {
        throw new Error(`some error occured while processing request. Response is ${stringify(response)}`);
    }
}
export function GetResponseShapeError(response, message) {
    let optionalMessage = "";
    if (typeof message !== undefined) {
        optionalMessage = `optional message:\n${message}`;
    }
    return new Error(`The response ${stringify(response)} does not match the expected shape. ${optionalMessage}`);
}
