import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
const BorderGridStyle = styled.div `
  display: grid;
  grid-template-columns: 1rem 1fr 1rem;
  grid-template-rows: 1rem 1fr 1rem;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100%;
  ${props => props.$background && `background: ${props.$background}`}
`;
export function BorderGrid({ children, background }) {
    return (_jsxs(BorderGridStyle, { "$background": background, children: [_jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), children, _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {})] }));
}
