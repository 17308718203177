import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
import { Node } from "../Node";
import { Graph } from "../Graph";
const GraphContext = createContext('initializing');
export const GraphProvider = ({ children, graph }) => {
    const [referenceGraph, setReferenceGraph] = useState(new Graph(graph.nodes, graph.links, ["Root"]));
    const getNodesLinkingToID = (id) => {
        return referenceGraph.getLinkedToNodes(id);
    };
    const executeNodeSearch = (search) => {
        let res_graph = referenceGraph.addSearchNode(search);
        let search_node_id = `Search Term ${search}`;
        let searchs_context_id = "meta_node: Searches";
        res_graph = res_graph.addNewNode(searchs_context_id, [{
                attributeName: "Description",
                attributeContent: "This Context links to all previously made searches.",
                visibility: "visible"
            }], "context", "visible", "User Searches");
        res_graph = res_graph.addNewLink(searchs_context_id, search_node_id);
        setReferenceGraph(res_graph);
        return search_node_id;
    };
    const getNodesLinkedFromID = (id) => {
        return referenceGraph.getLinkedFromNodes(id);
    };
    const getNodeForId = (id) => {
        return referenceGraph.getNodeForId(id);
    };
    const getVariablesPointingAtCurrentNode = (id) => {
        return Node.filterForVariables(getNodesLinkingToID(id));
    };
    const getVariablesPointedToByCurrentNode = (id) => {
        return Node.filterForVariables(getNodesLinkedFromID(id));
    };
    const getContextsPointingAtCurrentNode = (id) => {
        return Node.filterForContexts(getNodesLinkingToID(id));
    };
    const getContextsPointedToByCurrentNode = (id) => {
        return Node.filterForContexts(getNodesLinkedFromID(id));
    };
    const expose_value = {
        getNodesLinkingToID: getNodesLinkingToID,
        getNodesLinkedFromID: getNodesLinkedFromID,
        getNodeForId: getNodeForId,
        getContextsPointingAtCurrentNode: getContextsPointingAtCurrentNode,
        getContextsPointedToByCurrentNode: getContextsPointedToByCurrentNode,
        getVariablesPointedToByCurrentNode: getVariablesPointedToByCurrentNode,
        getVariablesPointingAtCurrentNode: getVariablesPointingAtCurrentNode,
        executeNodeSearch: executeNodeSearch,
    };
    return (_jsx(GraphContext.Provider, { value: expose_value, children: children }));
};
export function useGraph() {
    return useContext(GraphContext);
}
