import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const HELPER_form_style = styled.form `
    padding: 30px;
    padding-top: 156px;
    z-index: 1;
    position: relative;
    height: 100%;
`;
export function Positioning_login({ children, FormAction }) {
    return (_jsx(HELPER_form_style, { action: FormAction, children: children }));
}
