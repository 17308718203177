import styled from "styled-components";
export const NoticeText = styled.div `
    display: flex;
    background-color: rgb(240, 229, 251);
    padding: 8px;
    border-radius: 5px;
    border-style: solid;
    border-color: rgb(215, 201, 230);
    margin-bottom: 4px;
    text-align: justify;
    font-size: small;
    flex: 1 1 0%;
`;
