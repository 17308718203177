import { css } from "styled-components";
import { PanelBorderStyling } from "../Borders/PanelBorderStyling";
import { OverflowContainerVertical } from "../OverflowContainers/OverflowContainerVertical";
import styled from "styled-components";
import { RowGrid } from "../Grids/RowGrid";
export const ScrollingContentPanelStyle = css `
  padding: 15px;
  ${PanelBorderStyling}
  ${OverflowContainerVertical}
`;
export const ScrollingContentPanel = styled(RowGrid) `
  grid-column: ${props => props?.$grid_column};
  ${ScrollingContentPanelStyle};
`;
