import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { AutoHeightRowGrid } from "../../Grids/AutoHeightRowGrid";
import { RoundIconWrapper } from "../../Icons/Wrappers/RoundIconWrapper";
import { QuestionIcon } from "../../Icons/QuestionIcon";
import { Tooltip } from "../Hints/Tooltip";
import { MenuIcon } from "../../Icons/MenuIcon";
const OptionContainerStyling = styled.div `
  margin: 0.5em;
`;
function OptionContainer({ children }) {
    return (_jsx(OptionContainerStyling, { children: _jsx(AutoHeightRowGrid, { "$template_rows": "1fr", "$row_gap": "1rem", "$column_gap": "1rem", "$width": "max-content", children: children }) }));
}
function InfoOption({ tooltip_text }) {
    return (_jsx(Tooltip, { content: tooltip_text, children: _jsx(RoundIconWrapper, { children: _jsx(QuestionIcon, {}) }) }));
}
function MenuOption() {
    return (_jsx(RoundIconWrapper, { children: _jsx(MenuIcon, {}) }));
}
export function InfoMenu({ tooltip_text }) {
    return (_jsxs(OptionContainer, { children: [_jsx(InfoOption, { tooltip_text: tooltip_text }), _jsx(MenuOption, {})] }));
}
