import styled from "styled-components";
import { ThemeColor } from "../../ThemeColors";
export const RoundIconWrapper = styled.div `
  border-radius: 1rem;
  place-self: center;
  margin-left: ${props => props.$margin_left || "0px"};
  padding: ${props => props.$padding || "0px"};
  &:hover {
    background: ${ThemeColor.grey.hover};
    cursor: pointer;
  }
`;
