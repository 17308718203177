import styled from "styled-components";
import { keyframes } from "styled-components";
import "./loader.css";


const round = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const LaggingCircle = styled.div`
  color: #ffffff;
  font-size: 45px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  position: relative;
  animation: ${round} 1.7s infinite ease;
`

const Circle = styled.div`
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
`

const LoaderCentering = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export function CircleLoader(props){
  return (
    <LoaderCentering>
      <div className="loader"></div>
    </LoaderCentering>
  )
}