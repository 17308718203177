import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
import { PageElementContainer } from "../PageLayout/PageElementContainer";
import { MainContent } from "../PageLayout/MainContent";
import { ModalInput } from "../Styling/Components/Collapsibles/Modals/ModalInput";
import { MenuContent } from "../PageLayout/MenuArea/MenuContent";
import { MenuAreaCollapsed } from "../PageLayout/MenuArea/MenuAreaCollapsed";
import { MainInfoModal } from "../Styling/Components/Collapsibles/Modals/MainInfoModal";
import { Button_UncollapseMenuArea } from "../PageLayout/MenuArea/Buttons/Button_UncollapseMenuArea";
import { OverlayContentModal } from "../Styling/Components/Collapsibles/Modals/OverlayContentModal";
const LayoutContext = createContext('initializing');
export function PageLayoutProvider({ menu, content }) {
    console.log("render PageLayoutProvider");
    const [menuCollapsedFlag, setMenuCollapsedFlag] = useState(false);
    const [showModalFlag, setShowModalFlag] = useState(false);
    const [modalInput, setModalInput] = useState(null);
    const [overlayModalContent, setOverlayModalContent] = useState(null);
    const [showOverlayModal, setShowOverlayModal] = useState(false);
    const [menuArea, setMenuArea] = useState(_jsx(MenuContent, { children: menu }));
    const activateModal = (callback_close, callback_processing) => {
        const extended_callback = () => {
            setShowModalFlag(false);
            setModalInput(null);
            callback_close();
        };
        setModalInput(new ModalInput(extended_callback, callback_processing));
        setShowModalFlag(true);
    };
    const activateOverlayModal = (modal_content) => {
        setOverlayModalContent(modal_content);
        setShowOverlayModal(true);
    };
    const closeOverlayModal = () => {
        setShowOverlayModal(false);
    };
    const toggleMenuCollapse = () => {
        if (!menuCollapsedFlag) {
            setMenuArea(_jsx(MenuAreaCollapsed, { children: _jsx(Button_UncollapseMenuArea, {}) }));
        }
        else {
            setMenuArea(_jsx(MenuContent, { children: menu }));
        }
        setMenuCollapsedFlag(!menuCollapsedFlag);
    };
    const expose_value = {
        activateOverlayModal: activateOverlayModal,
        closeOverlayModal: closeOverlayModal,
        toggleMenuCollapse: toggleMenuCollapse,
        activateModal: activateModal
    };
    const getMainInfoModal = () => {
        if (showModalFlag && modalInput !== null)
            return _jsx(MainInfoModal, { modal_input: modalInput });
        return _jsx(_Fragment, {});
    };
    const getOverlayModal = () => {
        if (showOverlayModal && overlayModalContent !== null) {
            return _jsx(OverlayContentModal, { content: overlayModalContent, closeModalCallback: closeOverlayModal });
        }
        return _jsx(_Fragment, {});
    };
    return (_jsxs(LayoutContext.Provider, { value: expose_value, children: [getMainInfoModal(), getOverlayModal(), _jsxs(PageElementContainer, { children: [_jsx(MainContent, { children: content }), menuArea] })] }));
}
export const usePageLayout = () => { return useContext(LayoutContext); };
