import styled from "styled-components";
export const WidgetContainer_topRight = styled.div `
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    height: 30px;
    width: 30px;
    z-index: 1;
`;
