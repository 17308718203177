import styled from "styled-components";
import { Bold } from "./Bold";
import { ThemeColor } from "../ThemeColors";
export const UnderlineBold = styled(Bold) `
  &:before {
    content: "";
    position: absolute;
    transform: skew(-12deg) translateX(-50%);
    width: 33px;
    left: 10px;
    height: 5px;
    background: ${ThemeColor.lightred};
    top: 13px;
  }
`;
